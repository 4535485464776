@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Nunito");
/* --- Filey Specific Stylings --- */
@import "../css/app.css";
.filey h1, .filey h2, .filey h3, .filey h4, .filey h5, .filey h6,
.filey .h1, .filey .h2, .filey .h3, .filey .h4, .filey .h5, .filey .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.filey h1, .filey .h1 {
  font-size: 2.5rem;
}
.filey h2, .filey .h2 {
  font-size: 2rem;
}
.filey h3, .filey .h3 {
  font-size: 1.75rem;
}
.filey h4, .filey .h4 {
  font-size: 1.5rem;
}
.filey h5, .filey .h5 {
  font-size: 1.25rem;
}
.filey h6, .filey .h6 {
  font-size: 1rem;
}
.filey .lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.filey .display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}
.filey .display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.filey .display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.filey .display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.filey hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.filey small,
.filey .small {
  font-size: 80%;
  font-weight: 400;
}
.filey mark,
.filey .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.filey .list-unstyled {
  padding-left: 0;
  list-style: none;
}
.filey .list-inline {
  padding-left: 0;
  list-style: none;
}
.filey .list-inline-item {
  display: inline-block;
}
.filey .list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.filey .initialism {
  font-size: 90%;
  text-transform: uppercase;
}
.filey .blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.filey .blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.filey .blockquote-footer::before {
  content: "— ";
}
.filey .img-fluid {
  max-width: 100%;
  height: auto;
}
.filey .img-thumbnail {
  padding: 0.25rem;
  background-color: #f8fafc;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.filey .figure {
  display: inline-block;
}
.filey .figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.filey .figure-caption {
  font-size: 90%;
  color: #6c757d;
}
.filey code {
  font-size: 87.5%;
  color: #f66d9b;
  word-wrap: break-word;
}
a > .filey code {
  color: inherit;
}
.filey kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
.filey kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}
.filey pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
.filey pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.filey .pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
.filey .container,
.filey .container-fluid,
.filey .container-xl,
.filey .container-lg,
.filey .container-md,
.filey .container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .filey .container-sm, .filey .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .filey .container-md, .filey .container-sm, .filey .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .filey .container-lg, .filey .container-md, .filey .container-sm, .filey .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .filey .container-xl, .filey .container-lg, .filey .container-md, .filey .container-sm, .filey .container {
    max-width: 1140px;
  }
}
.filey .row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.filey .no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.filey .no-gutters > .col,
.filey .no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}
.filey .col-xl,
.filey .col-xl-auto, .filey .col-xl-12, .filey .col-xl-11, .filey .col-xl-10, .filey .col-xl-9, .filey .col-xl-8, .filey .col-xl-7, .filey .col-xl-6, .filey .col-xl-5, .filey .col-xl-4, .filey .col-xl-3, .filey .col-xl-2, .filey .col-xl-1, .filey .col-lg,
.filey .col-lg-auto, .filey .col-lg-12, .filey .col-lg-11, .filey .col-lg-10, .filey .col-lg-9, .filey .col-lg-8, .filey .col-lg-7, .filey .col-lg-6, .filey .col-lg-5, .filey .col-lg-4, .filey .col-lg-3, .filey .col-lg-2, .filey .col-lg-1, .filey .col-md,
.filey .col-md-auto, .filey .col-md-12, .filey .col-md-11, .filey .col-md-10, .filey .col-md-9, .filey .col-md-8, .filey .col-md-7, .filey .col-md-6, .filey .col-md-5, .filey .col-md-4, .filey .col-md-3, .filey .col-md-2, .filey .col-md-1, .filey .col-sm,
.filey .col-sm-auto, .filey .col-sm-12, .filey .col-sm-11, .filey .col-sm-10, .filey .col-sm-9, .filey .col-sm-8, .filey .col-sm-7, .filey .col-sm-6, .filey .col-sm-5, .filey .col-sm-4, .filey .col-sm-3, .filey .col-sm-2, .filey .col-sm-1, .filey .col,
.filey .col-auto, .filey .col-12, .filey .col-11, .filey .col-10, .filey .col-9, .filey .col-8, .filey .col-7, .filey .col-6, .filey .col-5, .filey .col-4, .filey .col-3, .filey .col-2, .filey .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.filey .col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.filey .row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}
.filey .row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}
.filey .row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.filey .row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}
.filey .row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}
.filey .row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
.filey .col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.filey .col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}
.filey .col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
.filey .col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.filey .col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.filey .col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}
.filey .col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.filey .col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}
.filey .col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}
.filey .col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.filey .col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}
.filey .col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}
.filey .col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.filey .order-first {
  order: -1;
}
.filey .order-last {
  order: 13;
}
.filey .order-0 {
  order: 0;
}
.filey .order-1 {
  order: 1;
}
.filey .order-2 {
  order: 2;
}
.filey .order-3 {
  order: 3;
}
.filey .order-4 {
  order: 4;
}
.filey .order-5 {
  order: 5;
}
.filey .order-6 {
  order: 6;
}
.filey .order-7 {
  order: 7;
}
.filey .order-8 {
  order: 8;
}
.filey .order-9 {
  order: 9;
}
.filey .order-10 {
  order: 10;
}
.filey .order-11 {
  order: 11;
}
.filey .order-12 {
  order: 12;
}
.filey .offset-1 {
  margin-left: 8.3333333333%;
}
.filey .offset-2 {
  margin-left: 16.6666666667%;
}
.filey .offset-3 {
  margin-left: 25%;
}
.filey .offset-4 {
  margin-left: 33.3333333333%;
}
.filey .offset-5 {
  margin-left: 41.6666666667%;
}
.filey .offset-6 {
  margin-left: 50%;
}
.filey .offset-7 {
  margin-left: 58.3333333333%;
}
.filey .offset-8 {
  margin-left: 66.6666666667%;
}
.filey .offset-9 {
  margin-left: 75%;
}
.filey .offset-10 {
  margin-left: 83.3333333333%;
}
.filey .offset-11 {
  margin-left: 91.6666666667%;
}
@media (min-width: 576px) {
  .filey .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .filey .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .filey .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .filey .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .filey .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .filey .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .filey .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .filey .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .filey .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .filey .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .filey .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .filey .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .filey .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .filey .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .filey .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .filey .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .filey .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .filey .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .filey .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .filey .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .filey .order-sm-first {
    order: -1;
  }
  .filey .order-sm-last {
    order: 13;
  }
  .filey .order-sm-0 {
    order: 0;
  }
  .filey .order-sm-1 {
    order: 1;
  }
  .filey .order-sm-2 {
    order: 2;
  }
  .filey .order-sm-3 {
    order: 3;
  }
  .filey .order-sm-4 {
    order: 4;
  }
  .filey .order-sm-5 {
    order: 5;
  }
  .filey .order-sm-6 {
    order: 6;
  }
  .filey .order-sm-7 {
    order: 7;
  }
  .filey .order-sm-8 {
    order: 8;
  }
  .filey .order-sm-9 {
    order: 9;
  }
  .filey .order-sm-10 {
    order: 10;
  }
  .filey .order-sm-11 {
    order: 11;
  }
  .filey .order-sm-12 {
    order: 12;
  }
  .filey .offset-sm-0 {
    margin-left: 0;
  }
  .filey .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .filey .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .filey .offset-sm-3 {
    margin-left: 25%;
  }
  .filey .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .filey .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .filey .offset-sm-6 {
    margin-left: 50%;
  }
  .filey .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .filey .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .filey .offset-sm-9 {
    margin-left: 75%;
  }
  .filey .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .filey .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .filey .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .filey .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .filey .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .filey .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .filey .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .filey .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .filey .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .filey .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .filey .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .filey .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .filey .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .filey .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .filey .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .filey .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .filey .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .filey .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .filey .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .filey .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .filey .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .filey .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .filey .order-md-first {
    order: -1;
  }
  .filey .order-md-last {
    order: 13;
  }
  .filey .order-md-0 {
    order: 0;
  }
  .filey .order-md-1 {
    order: 1;
  }
  .filey .order-md-2 {
    order: 2;
  }
  .filey .order-md-3 {
    order: 3;
  }
  .filey .order-md-4 {
    order: 4;
  }
  .filey .order-md-5 {
    order: 5;
  }
  .filey .order-md-6 {
    order: 6;
  }
  .filey .order-md-7 {
    order: 7;
  }
  .filey .order-md-8 {
    order: 8;
  }
  .filey .order-md-9 {
    order: 9;
  }
  .filey .order-md-10 {
    order: 10;
  }
  .filey .order-md-11 {
    order: 11;
  }
  .filey .order-md-12 {
    order: 12;
  }
  .filey .offset-md-0 {
    margin-left: 0;
  }
  .filey .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .filey .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .filey .offset-md-3 {
    margin-left: 25%;
  }
  .filey .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .filey .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .filey .offset-md-6 {
    margin-left: 50%;
  }
  .filey .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .filey .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .filey .offset-md-9 {
    margin-left: 75%;
  }
  .filey .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .filey .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .filey .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .filey .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .filey .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .filey .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .filey .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .filey .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .filey .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .filey .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .filey .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .filey .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .filey .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .filey .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .filey .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .filey .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .filey .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .filey .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .filey .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .filey .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .filey .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .filey .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .filey .order-lg-first {
    order: -1;
  }
  .filey .order-lg-last {
    order: 13;
  }
  .filey .order-lg-0 {
    order: 0;
  }
  .filey .order-lg-1 {
    order: 1;
  }
  .filey .order-lg-2 {
    order: 2;
  }
  .filey .order-lg-3 {
    order: 3;
  }
  .filey .order-lg-4 {
    order: 4;
  }
  .filey .order-lg-5 {
    order: 5;
  }
  .filey .order-lg-6 {
    order: 6;
  }
  .filey .order-lg-7 {
    order: 7;
  }
  .filey .order-lg-8 {
    order: 8;
  }
  .filey .order-lg-9 {
    order: 9;
  }
  .filey .order-lg-10 {
    order: 10;
  }
  .filey .order-lg-11 {
    order: 11;
  }
  .filey .order-lg-12 {
    order: 12;
  }
  .filey .offset-lg-0 {
    margin-left: 0;
  }
  .filey .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .filey .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .filey .offset-lg-3 {
    margin-left: 25%;
  }
  .filey .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .filey .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .filey .offset-lg-6 {
    margin-left: 50%;
  }
  .filey .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .filey .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .filey .offset-lg-9 {
    margin-left: 75%;
  }
  .filey .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .filey .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .filey .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .filey .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .filey .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .filey .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .filey .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .filey .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .filey .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .filey .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .filey .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .filey .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .filey .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .filey .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .filey .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .filey .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .filey .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .filey .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .filey .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .filey .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .filey .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .filey .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .filey .order-xl-first {
    order: -1;
  }
  .filey .order-xl-last {
    order: 13;
  }
  .filey .order-xl-0 {
    order: 0;
  }
  .filey .order-xl-1 {
    order: 1;
  }
  .filey .order-xl-2 {
    order: 2;
  }
  .filey .order-xl-3 {
    order: 3;
  }
  .filey .order-xl-4 {
    order: 4;
  }
  .filey .order-xl-5 {
    order: 5;
  }
  .filey .order-xl-6 {
    order: 6;
  }
  .filey .order-xl-7 {
    order: 7;
  }
  .filey .order-xl-8 {
    order: 8;
  }
  .filey .order-xl-9 {
    order: 9;
  }
  .filey .order-xl-10 {
    order: 10;
  }
  .filey .order-xl-11 {
    order: 11;
  }
  .filey .order-xl-12 {
    order: 12;
  }
  .filey .offset-xl-0 {
    margin-left: 0;
  }
  .filey .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .filey .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .filey .offset-xl-3 {
    margin-left: 25%;
  }
  .filey .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .filey .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .filey .offset-xl-6 {
    margin-left: 50%;
  }
  .filey .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .filey .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .filey .offset-xl-9 {
    margin-left: 75%;
  }
  .filey .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .filey .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.filey .table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.filey .table th,
.filey .table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.filey .table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.filey .table tbody + tbody {
  border-top: 2px solid #dee2e6;
}
.filey .table-sm th,
.filey .table-sm td {
  padding: 0.3rem;
}
.filey .table-bordered {
  border: 1px solid #dee2e6;
}
.filey .table-bordered th,
.filey .table-bordered td {
  border: 1px solid #dee2e6;
}
.filey .table-bordered thead th,
.filey .table-bordered thead td {
  border-bottom-width: 2px;
}
.filey .table-borderless th,
.filey .table-borderless td,
.filey .table-borderless thead th,
.filey .table-borderless tbody + tbody {
  border: 0;
}
.filey .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.filey .table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}
.filey .table-primary,
.filey .table-primary > th,
.filey .table-primary > td {
  background-color: #c6e0f5;
}
.filey .table-primary th,
.filey .table-primary td,
.filey .table-primary thead th,
.filey .table-primary tbody + tbody {
  border-color: #95c5ed;
}
.filey .table-hover .table-primary:hover {
  background-color: #b0d4f1;
}
.filey .table-hover .table-primary:hover > td,
.filey .table-hover .table-primary:hover > th {
  background-color: #b0d4f1;
}
.filey .table-secondary,
.filey .table-secondary > th,
.filey .table-secondary > td {
  background-color: #d6d8db;
}
.filey .table-secondary th,
.filey .table-secondary td,
.filey .table-secondary thead th,
.filey .table-secondary tbody + tbody {
  border-color: #b3b7bb;
}
.filey .table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.filey .table-hover .table-secondary:hover > td,
.filey .table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}
.filey .table-success,
.filey .table-success > th,
.filey .table-success > td {
  background-color: #c7eed8;
}
.filey .table-success th,
.filey .table-success td,
.filey .table-success thead th,
.filey .table-success tbody + tbody {
  border-color: #98dfb6;
}
.filey .table-hover .table-success:hover {
  background-color: #b3e8ca;
}
.filey .table-hover .table-success:hover > td,
.filey .table-hover .table-success:hover > th {
  background-color: #b3e8ca;
}
.filey .table-info,
.filey .table-info > th,
.filey .table-info > td {
  background-color: #d6e9f9;
}
.filey .table-info th,
.filey .table-info td,
.filey .table-info thead th,
.filey .table-info tbody + tbody {
  border-color: #b3d7f5;
}
.filey .table-hover .table-info:hover {
  background-color: #c0ddf6;
}
.filey .table-hover .table-info:hover > td,
.filey .table-hover .table-info:hover > th {
  background-color: #c0ddf6;
}
.filey .table-warning,
.filey .table-warning > th,
.filey .table-warning > td {
  background-color: #fffacc;
}
.filey .table-warning th,
.filey .table-warning td,
.filey .table-warning thead th,
.filey .table-warning tbody + tbody {
  border-color: #fff6a1;
}
.filey .table-hover .table-warning:hover {
  background-color: #fff8b3;
}
.filey .table-hover .table-warning:hover > td,
.filey .table-hover .table-warning:hover > th {
  background-color: #fff8b3;
}
.filey .table-danger,
.filey .table-danger > th,
.filey .table-danger > td {
  background-color: #f7c6c5;
}
.filey .table-danger th,
.filey .table-danger td,
.filey .table-danger thead th,
.filey .table-danger tbody + tbody {
  border-color: #f09593;
}
.filey .table-hover .table-danger:hover {
  background-color: #f4b0af;
}
.filey .table-hover .table-danger:hover > td,
.filey .table-hover .table-danger:hover > th {
  background-color: #f4b0af;
}
.filey .table-light,
.filey .table-light > th,
.filey .table-light > td {
  background-color: #fdfdfe;
}
.filey .table-light th,
.filey .table-light td,
.filey .table-light thead th,
.filey .table-light tbody + tbody {
  border-color: #fbfcfc;
}
.filey .table-hover .table-light:hover {
  background-color: #ececf6;
}
.filey .table-hover .table-light:hover > td,
.filey .table-hover .table-light:hover > th {
  background-color: #ececf6;
}
.filey .table-dark,
.filey .table-dark > th,
.filey .table-dark > td {
  background-color: #c6c8ca;
}
.filey .table-dark th,
.filey .table-dark td,
.filey .table-dark thead th,
.filey .table-dark tbody + tbody {
  border-color: #95999c;
}
.filey .table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.filey .table-hover .table-dark:hover > td,
.filey .table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}
.filey .table-active,
.filey .table-active > th,
.filey .table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}
.filey .table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.filey .table-hover .table-active:hover > td,
.filey .table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}
.filey .table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.filey .table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.filey .table-dark {
  color: #fff;
  background-color: #343a40;
}
.filey .table-dark th,
.filey .table-dark td,
.filey .table-dark thead th {
  border-color: #454d55;
}
.filey .table-dark.table-bordered {
  border: 0;
}
.filey .table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.filey .table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}
@media (max-width: 575.98px) {
  .filey .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .filey .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .filey .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .filey .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .filey .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .filey .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .filey .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .filey .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.filey .table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.filey .table-responsive > .table-bordered {
  border: 0;
}
.filey .form-control {
  display: block;
  width: 100%;
  height: calc(1.6em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .filey .form-control {
    transition: none;
  }
}
.filey .form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.filey .form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.filey .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #a1cbef;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}
.filey .form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.filey .form-control:disabled, .filey .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.filey input[type=date].form-control,
.filey input[type=time].form-control,
.filey input[type=datetime-local].form-control,
.filey input[type=month].form-control {
  appearance: none;
}
.filey select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.filey .form-control-file,
.filey .form-control-range {
  display: block;
  width: 100%;
}
.filey .col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6;
}
.filey .col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}
.filey .col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}
.filey .form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.6;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.filey .form-control-plaintext.form-control-sm, .filey .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}
.filey .form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.filey .form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.filey select.form-control[size], .filey select.form-control[multiple] {
  height: auto;
}
.filey textarea.form-control {
  height: auto;
}
.filey .form-group {
  margin-bottom: 1rem;
}
.filey .form-text {
  display: block;
  margin-top: 0.25rem;
}
.filey .form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.filey .form-row > .col,
.filey .form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}
.filey .form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.filey .form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.filey .form-check-input[disabled] ~ .form-check-label, .filey .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}
.filey .form-check-label {
  margin-bottom: 0;
}
.filey .form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.filey .form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.filey .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #38c172;
}
.filey .valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.6;
  color: #fff;
  background-color: rgba(56, 193, 114, 0.9);
  border-radius: 0.25rem;
}
.was-validated .filey:valid ~ .valid-feedback,
.was-validated .filey:valid ~ .valid-tooltip, .filey.is-valid ~ .valid-feedback,
.filey.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .filey .form-control:valid, .filey .form-control.is-valid {
  border-color: #38c172;
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2338c172' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .filey .form-control:valid:focus, .filey .form-control.is-valid:focus {
  border-color: #38c172;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}
.was-validated .filey textarea.form-control:valid, .filey textarea.form-control.is-valid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem);
}
.was-validated .filey .custom-select:valid, .filey .custom-select.is-valid {
  border-color: #38c172;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2338c172' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .filey .custom-select:valid:focus, .filey .custom-select.is-valid:focus {
  border-color: #38c172;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}
.was-validated .filey .form-check-input:valid ~ .form-check-label, .filey .form-check-input.is-valid ~ .form-check-label {
  color: #38c172;
}
.was-validated .filey .form-check-input:valid ~ .valid-feedback,
.was-validated .filey .form-check-input:valid ~ .valid-tooltip, .filey .form-check-input.is-valid ~ .valid-feedback,
.filey .form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .filey .custom-control-input:valid ~ .custom-control-label, .filey .custom-control-input.is-valid ~ .custom-control-label {
  color: #38c172;
}
.was-validated .filey .custom-control-input:valid ~ .custom-control-label::before, .filey .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #38c172;
}
.was-validated .filey .custom-control-input:valid:checked ~ .custom-control-label::before, .filey .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #5cd08d;
  background-color: #5cd08d;
}
.was-validated .filey .custom-control-input:valid:focus ~ .custom-control-label::before, .filey .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}
.was-validated .filey .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .filey .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #38c172;
}
.was-validated .filey .custom-file-input:valid ~ .custom-file-label, .filey .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #38c172;
}
.was-validated .filey .custom-file-input:valid:focus ~ .custom-file-label, .filey .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #38c172;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}
.filey .invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e3342f;
}
.filey .invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.6;
  color: #fff;
  background-color: rgba(227, 52, 47, 0.9);
  border-radius: 0.25rem;
}
.was-validated .filey:invalid ~ .invalid-feedback,
.was-validated .filey:invalid ~ .invalid-tooltip, .filey.is-invalid ~ .invalid-feedback,
.filey.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .filey .form-control:invalid, .filey .form-control.is-invalid {
  border-color: #e3342f;
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e3342f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e3342f' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .filey .form-control:invalid:focus, .filey .form-control.is-invalid:focus {
  border-color: #e3342f;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}
.was-validated .filey textarea.form-control:invalid, .filey textarea.form-control.is-invalid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem);
}
.was-validated .filey .custom-select:invalid, .filey .custom-select.is-invalid {
  border-color: #e3342f;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e3342f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e3342f' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .filey .custom-select:invalid:focus, .filey .custom-select.is-invalid:focus {
  border-color: #e3342f;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}
.was-validated .filey .form-check-input:invalid ~ .form-check-label, .filey .form-check-input.is-invalid ~ .form-check-label {
  color: #e3342f;
}
.was-validated .filey .form-check-input:invalid ~ .invalid-feedback,
.was-validated .filey .form-check-input:invalid ~ .invalid-tooltip, .filey .form-check-input.is-invalid ~ .invalid-feedback,
.filey .form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .filey .custom-control-input:invalid ~ .custom-control-label, .filey .custom-control-input.is-invalid ~ .custom-control-label {
  color: #e3342f;
}
.was-validated .filey .custom-control-input:invalid ~ .custom-control-label::before, .filey .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #e3342f;
}
.was-validated .filey .custom-control-input:invalid:checked ~ .custom-control-label::before, .filey .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e9605c;
  background-color: #e9605c;
}
.was-validated .filey .custom-control-input:invalid:focus ~ .custom-control-label::before, .filey .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}
.was-validated .filey .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .filey .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #e3342f;
}
.was-validated .filey .custom-file-input:invalid ~ .custom-file-label, .filey .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #e3342f;
}
.was-validated .filey .custom-file-input:invalid:focus ~ .custom-file-label, .filey .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #e3342f;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}
.filey .form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.filey .form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .filey .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .filey .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .filey .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .filey .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .filey .form-inline .input-group,
.filey .form-inline .custom-select {
    width: auto;
  }
  .filey .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .filey .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .filey .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .filey .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
.filey .btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.6;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .filey .btn {
    transition: none;
  }
}
.filey .btn:hover {
  color: #212529;
  text-decoration: none;
}
.filey .btn:focus, .filey .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}
.filey .btn.disabled, .filey .btn:disabled {
  opacity: 0.65;
}
.filey .btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.filey a.btn.disabled,
.filey fieldset:disabled a.btn {
  pointer-events: none;
}
.filey .btn-primary {
  color: #fff;
  background-color: #3490dc;
  border-color: #3490dc;
}
.filey .btn-primary:hover {
  color: #fff;
  background-color: #227dc7;
  border-color: #2176bd;
}
.filey .btn-primary:focus, .filey .btn-primary.focus {
  color: #fff;
  background-color: #227dc7;
  border-color: #2176bd;
  box-shadow: 0 0 0 0.2rem rgba(82, 161, 225, 0.5);
}
.filey .btn-primary.disabled, .filey .btn-primary:disabled {
  color: #fff;
  background-color: #3490dc;
  border-color: #3490dc;
}
.filey .btn-primary:not(:disabled):not(.disabled):active, .filey .btn-primary:not(:disabled):not(.disabled).active, .show > .filey .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #2176bd;
  border-color: #1f6fb2;
}
.filey .btn-primary:not(:disabled):not(.disabled):active:focus, .filey .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .filey .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 161, 225, 0.5);
}
.filey .btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.filey .btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.filey .btn-secondary:focus, .filey .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.filey .btn-secondary.disabled, .filey .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.filey .btn-secondary:not(:disabled):not(.disabled):active, .filey .btn-secondary:not(:disabled):not(.disabled).active, .show > .filey .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.filey .btn-secondary:not(:disabled):not(.disabled):active:focus, .filey .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .filey .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.filey .btn-success {
  color: #fff;
  background-color: #38c172;
  border-color: #38c172;
}
.filey .btn-success:hover {
  color: #fff;
  background-color: #2fa360;
  border-color: #2d995b;
}
.filey .btn-success:focus, .filey .btn-success.focus {
  color: #fff;
  background-color: #2fa360;
  border-color: #2d995b;
  box-shadow: 0 0 0 0.2rem rgba(86, 202, 135, 0.5);
}
.filey .btn-success.disabled, .filey .btn-success:disabled {
  color: #fff;
  background-color: #38c172;
  border-color: #38c172;
}
.filey .btn-success:not(:disabled):not(.disabled):active, .filey .btn-success:not(:disabled):not(.disabled).active, .show > .filey .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #2d995b;
  border-color: #2a9055;
}
.filey .btn-success:not(:disabled):not(.disabled):active:focus, .filey .btn-success:not(:disabled):not(.disabled).active:focus, .show > .filey .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(86, 202, 135, 0.5);
}
.filey .btn-info {
  color: #212529;
  background-color: #6cb2eb;
  border-color: #6cb2eb;
}
.filey .btn-info:hover {
  color: #fff;
  background-color: #4aa0e6;
  border-color: #3f9ae5;
}
.filey .btn-info:focus, .filey .btn-info.focus {
  color: #fff;
  background-color: #4aa0e6;
  border-color: #3f9ae5;
  box-shadow: 0 0 0 0.2rem rgba(97, 157, 206, 0.5);
}
.filey .btn-info.disabled, .filey .btn-info:disabled {
  color: #212529;
  background-color: #6cb2eb;
  border-color: #6cb2eb;
}
.filey .btn-info:not(:disabled):not(.disabled):active, .filey .btn-info:not(:disabled):not(.disabled).active, .show > .filey .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #3f9ae5;
  border-color: #3495e3;
}
.filey .btn-info:not(:disabled):not(.disabled):active:focus, .filey .btn-info:not(:disabled):not(.disabled).active:focus, .show > .filey .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(97, 157, 206, 0.5);
}
.filey .btn-warning {
  color: #212529;
  background-color: #ffed4a;
  border-color: #ffed4a;
}
.filey .btn-warning:hover {
  color: #212529;
  background-color: #ffe924;
  border-color: #ffe817;
}
.filey .btn-warning:focus, .filey .btn-warning.focus {
  color: #212529;
  background-color: #ffe924;
  border-color: #ffe817;
  box-shadow: 0 0 0 0.2rem rgba(222, 207, 69, 0.5);
}
.filey .btn-warning.disabled, .filey .btn-warning:disabled {
  color: #212529;
  background-color: #ffed4a;
  border-color: #ffed4a;
}
.filey .btn-warning:not(:disabled):not(.disabled):active, .filey .btn-warning:not(:disabled):not(.disabled).active, .show > .filey .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffe817;
  border-color: #ffe70a;
}
.filey .btn-warning:not(:disabled):not(.disabled):active:focus, .filey .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .filey .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 207, 69, 0.5);
}
.filey .btn-danger {
  color: #fff;
  background-color: #e3342f;
  border-color: #e3342f;
}
.filey .btn-danger:hover {
  color: #fff;
  background-color: #d0211c;
  border-color: #c51f1a;
}
.filey .btn-danger:focus, .filey .btn-danger.focus {
  color: #fff;
  background-color: #d0211c;
  border-color: #c51f1a;
  box-shadow: 0 0 0 0.2rem rgba(231, 82, 78, 0.5);
}
.filey .btn-danger.disabled, .filey .btn-danger:disabled {
  color: #fff;
  background-color: #e3342f;
  border-color: #e3342f;
}
.filey .btn-danger:not(:disabled):not(.disabled):active, .filey .btn-danger:not(:disabled):not(.disabled).active, .show > .filey .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c51f1a;
  border-color: #b91d19;
}
.filey .btn-danger:not(:disabled):not(.disabled):active:focus, .filey .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .filey .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(231, 82, 78, 0.5);
}
.filey .btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.filey .btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.filey .btn-light:focus, .filey .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.filey .btn-light.disabled, .filey .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.filey .btn-light:not(:disabled):not(.disabled):active, .filey .btn-light:not(:disabled):not(.disabled).active, .show > .filey .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.filey .btn-light:not(:disabled):not(.disabled):active:focus, .filey .btn-light:not(:disabled):not(.disabled).active:focus, .show > .filey .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.filey .btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.filey .btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.filey .btn-dark:focus, .filey .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.filey .btn-dark.disabled, .filey .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.filey .btn-dark:not(:disabled):not(.disabled):active, .filey .btn-dark:not(:disabled):not(.disabled).active, .show > .filey .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.filey .btn-dark:not(:disabled):not(.disabled):active:focus, .filey .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .filey .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.filey .btn-outline-primary {
  color: #3490dc;
  border-color: #3490dc;
}
.filey .btn-outline-primary:hover {
  color: #fff;
  background-color: #3490dc;
  border-color: #3490dc;
}
.filey .btn-outline-primary:focus, .filey .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.5);
}
.filey .btn-outline-primary.disabled, .filey .btn-outline-primary:disabled {
  color: #3490dc;
  background-color: transparent;
}
.filey .btn-outline-primary:not(:disabled):not(.disabled):active, .filey .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .filey .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #3490dc;
  border-color: #3490dc;
}
.filey .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .filey .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .filey .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.5);
}
.filey .btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.filey .btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.filey .btn-outline-secondary:focus, .filey .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.filey .btn-outline-secondary.disabled, .filey .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.filey .btn-outline-secondary:not(:disabled):not(.disabled):active, .filey .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .filey .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.filey .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .filey .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .filey .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.filey .btn-outline-success {
  color: #38c172;
  border-color: #38c172;
}
.filey .btn-outline-success:hover {
  color: #fff;
  background-color: #38c172;
  border-color: #38c172;
}
.filey .btn-outline-success:focus, .filey .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.5);
}
.filey .btn-outline-success.disabled, .filey .btn-outline-success:disabled {
  color: #38c172;
  background-color: transparent;
}
.filey .btn-outline-success:not(:disabled):not(.disabled):active, .filey .btn-outline-success:not(:disabled):not(.disabled).active, .show > .filey .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #38c172;
  border-color: #38c172;
}
.filey .btn-outline-success:not(:disabled):not(.disabled):active:focus, .filey .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .filey .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.5);
}
.filey .btn-outline-info {
  color: #6cb2eb;
  border-color: #6cb2eb;
}
.filey .btn-outline-info:hover {
  color: #212529;
  background-color: #6cb2eb;
  border-color: #6cb2eb;
}
.filey .btn-outline-info:focus, .filey .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 178, 235, 0.5);
}
.filey .btn-outline-info.disabled, .filey .btn-outline-info:disabled {
  color: #6cb2eb;
  background-color: transparent;
}
.filey .btn-outline-info:not(:disabled):not(.disabled):active, .filey .btn-outline-info:not(:disabled):not(.disabled).active, .show > .filey .btn-outline-info.dropdown-toggle {
  color: #212529;
  background-color: #6cb2eb;
  border-color: #6cb2eb;
}
.filey .btn-outline-info:not(:disabled):not(.disabled):active:focus, .filey .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .filey .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 178, 235, 0.5);
}
.filey .btn-outline-warning {
  color: #ffed4a;
  border-color: #ffed4a;
}
.filey .btn-outline-warning:hover {
  color: #212529;
  background-color: #ffed4a;
  border-color: #ffed4a;
}
.filey .btn-outline-warning:focus, .filey .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 237, 74, 0.5);
}
.filey .btn-outline-warning.disabled, .filey .btn-outline-warning:disabled {
  color: #ffed4a;
  background-color: transparent;
}
.filey .btn-outline-warning:not(:disabled):not(.disabled):active, .filey .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .filey .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffed4a;
  border-color: #ffed4a;
}
.filey .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .filey .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .filey .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 237, 74, 0.5);
}
.filey .btn-outline-danger {
  color: #e3342f;
  border-color: #e3342f;
}
.filey .btn-outline-danger:hover {
  color: #fff;
  background-color: #e3342f;
  border-color: #e3342f;
}
.filey .btn-outline-danger:focus, .filey .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.5);
}
.filey .btn-outline-danger.disabled, .filey .btn-outline-danger:disabled {
  color: #e3342f;
  background-color: transparent;
}
.filey .btn-outline-danger:not(:disabled):not(.disabled):active, .filey .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .filey .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #e3342f;
  border-color: #e3342f;
}
.filey .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .filey .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .filey .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.5);
}
.filey .btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.filey .btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.filey .btn-outline-light:focus, .filey .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.filey .btn-outline-light.disabled, .filey .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.filey .btn-outline-light:not(:disabled):not(.disabled):active, .filey .btn-outline-light:not(:disabled):not(.disabled).active, .show > .filey .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.filey .btn-outline-light:not(:disabled):not(.disabled):active:focus, .filey .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .filey .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.filey .btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.filey .btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.filey .btn-outline-dark:focus, .filey .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.filey .btn-outline-dark.disabled, .filey .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.filey .btn-outline-dark:not(:disabled):not(.disabled):active, .filey .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .filey .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.filey .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .filey .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .filey .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.filey .btn-link {
  font-weight: 400;
  color: #3490dc;
  text-decoration: none;
}
.filey .btn-link:hover {
  color: #1d68a7;
  text-decoration: underline;
}
.filey .btn-link:focus, .filey .btn-link.focus {
  text-decoration: underline;
}
.filey .btn-link:disabled, .filey .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}
.filey .btn-lg, .filey .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.filey .btn-sm, .filey .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.filey .btn-block {
  display: block;
  width: 100%;
}
.filey .btn-block + .btn-block {
  margin-top: 0.5rem;
}
.filey input[type=submit].btn-block,
.filey input[type=reset].btn-block,
.filey input[type=button].btn-block {
  width: 100%;
}
.filey .fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .filey .fade {
    transition: none;
  }
}
.filey .fade:not(.show) {
  opacity: 0;
}
.filey .collapse:not(.show) {
  display: none;
}
.filey .collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .filey .collapsing {
    transition: none;
  }
}
.filey .dropup,
.filey .dropright,
.filey .dropdown,
.filey .dropleft {
  position: relative;
}
.filey .dropdown-toggle {
  white-space: nowrap;
}
.filey .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.filey .dropdown-toggle:empty::after {
  margin-left: 0;
}
.filey .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.filey .dropdown-menu-left {
  right: auto;
  left: 0;
}
.filey .dropdown-menu-right {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .filey .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .filey .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .filey .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .filey .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .filey .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .filey .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .filey .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .filey .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.filey .dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.filey .dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.filey .dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.filey .dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.filey .dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.filey .dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.filey .dropright .dropdown-toggle::after {
  vertical-align: 0;
}
.filey .dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.filey .dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.filey .dropleft .dropdown-toggle::after {
  display: none;
}
.filey .dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.filey .dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.filey .dropleft .dropdown-toggle::before {
  vertical-align: 0;
}
.filey .dropdown-menu[x-placement^=top], .filey .dropdown-menu[x-placement^=right], .filey .dropdown-menu[x-placement^=bottom], .filey .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}
.filey .dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
.filey .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.filey .dropdown-item:hover, .filey .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.filey .dropdown-item.active, .filey .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #3490dc;
}
.filey .dropdown-item.disabled, .filey .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}
.filey .dropdown-menu.show {
  display: block;
}
.filey .dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}
.filey .dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}
.filey .btn-group,
.filey .btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.filey .btn-group > .btn,
.filey .btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.filey .btn-group > .btn:hover,
.filey .btn-group-vertical > .btn:hover {
  z-index: 1;
}
.filey .btn-group > .btn:focus, .filey .btn-group > .btn:active, .filey .btn-group > .btn.active,
.filey .btn-group-vertical > .btn:focus,
.filey .btn-group-vertical > .btn:active,
.filey .btn-group-vertical > .btn.active {
  z-index: 1;
}
.filey .btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.filey .btn-toolbar .input-group {
  width: auto;
}
.filey .btn-group > .btn:not(:first-child),
.filey .btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.filey .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.filey .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.filey .btn-group > .btn:not(:first-child),
.filey .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.filey .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.filey .dropdown-toggle-split::after, .dropup .filey .dropdown-toggle-split::after, .dropright .filey .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .filey .dropdown-toggle-split::before {
  margin-right: 0;
}
.filey .btn-sm + .dropdown-toggle-split, .filey .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}
.filey .btn-lg + .dropdown-toggle-split, .filey .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.filey .btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.filey .btn-group-vertical > .btn,
.filey .btn-group-vertical > .btn-group {
  width: 100%;
}
.filey .btn-group-vertical > .btn:not(:first-child),
.filey .btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.filey .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.filey .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.filey .btn-group-vertical > .btn:not(:first-child),
.filey .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.filey .btn-group-toggle > .btn,
.filey .btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.filey .btn-group-toggle > .btn input[type=radio],
.filey .btn-group-toggle > .btn input[type=checkbox],
.filey .btn-group-toggle > .btn-group > .btn input[type=radio],
.filey .btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.filey .input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.filey .input-group > .form-control,
.filey .input-group > .form-control-plaintext,
.filey .input-group > .custom-select,
.filey .input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.filey .input-group > .form-control + .form-control,
.filey .input-group > .form-control + .custom-select,
.filey .input-group > .form-control + .custom-file,
.filey .input-group > .form-control-plaintext + .form-control,
.filey .input-group > .form-control-plaintext + .custom-select,
.filey .input-group > .form-control-plaintext + .custom-file,
.filey .input-group > .custom-select + .form-control,
.filey .input-group > .custom-select + .custom-select,
.filey .input-group > .custom-select + .custom-file,
.filey .input-group > .custom-file + .form-control,
.filey .input-group > .custom-file + .custom-select,
.filey .input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.filey .input-group > .form-control:focus,
.filey .input-group > .custom-select:focus,
.filey .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.filey .input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.filey .input-group > .form-control:not(:last-child),
.filey .input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.filey .input-group > .form-control:not(:first-child),
.filey .input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.filey .input-group > .custom-file {
  display: flex;
  align-items: center;
}
.filey .input-group > .custom-file:not(:last-child) .custom-file-label, .filey .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.filey .input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.filey .input-group-prepend,
.filey .input-group-append {
  display: flex;
}
.filey .input-group-prepend .btn,
.filey .input-group-append .btn {
  position: relative;
  z-index: 2;
}
.filey .input-group-prepend .btn:focus,
.filey .input-group-append .btn:focus {
  z-index: 3;
}
.filey .input-group-prepend .btn + .btn,
.filey .input-group-prepend .btn + .input-group-text,
.filey .input-group-prepend .input-group-text + .input-group-text,
.filey .input-group-prepend .input-group-text + .btn,
.filey .input-group-append .btn + .btn,
.filey .input-group-append .btn + .input-group-text,
.filey .input-group-append .input-group-text + .input-group-text,
.filey .input-group-append .input-group-text + .btn {
  margin-left: -1px;
}
.filey .input-group-prepend {
  margin-right: -1px;
}
.filey .input-group-append {
  margin-left: -1px;
}
.filey .input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.filey .input-group-text input[type=radio],
.filey .input-group-text input[type=checkbox] {
  margin-top: 0;
}
.filey .input-group-lg > .form-control:not(textarea),
.filey .input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}
.filey .input-group-lg > .form-control,
.filey .input-group-lg > .custom-select,
.filey .input-group-lg > .input-group-prepend > .input-group-text,
.filey .input-group-lg > .input-group-append > .input-group-text,
.filey .input-group-lg > .input-group-prepend > .btn,
.filey .input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.filey .input-group-sm > .form-control:not(textarea),
.filey .input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}
.filey .input-group-sm > .form-control,
.filey .input-group-sm > .custom-select,
.filey .input-group-sm > .input-group-prepend > .input-group-text,
.filey .input-group-sm > .input-group-append > .input-group-text,
.filey .input-group-sm > .input-group-prepend > .btn,
.filey .input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.filey .input-group-lg > .custom-select,
.filey .input-group-sm > .custom-select {
  padding-right: 1.75rem;
}
.filey .input-group > .input-group-prepend > .btn,
.filey .input-group > .input-group-prepend > .input-group-text,
.filey .input-group > .input-group-append:not(:last-child) > .btn,
.filey .input-group > .input-group-append:not(:last-child) > .input-group-text,
.filey .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.filey .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.filey .input-group > .input-group-append > .btn,
.filey .input-group > .input-group-append > .input-group-text,
.filey .input-group > .input-group-prepend:not(:first-child) > .btn,
.filey .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.filey .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.filey .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.filey .custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.6rem;
  padding-left: 1.5rem;
}
.filey .custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}
.filey .custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.3rem;
  opacity: 0;
}
.filey .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #3490dc;
  background-color: #3490dc;
}
.filey .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}
.filey .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #a1cbef;
}
.filey .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #cce3f6;
  border-color: #cce3f6;
}
.filey .custom-control-input[disabled] ~ .custom-control-label, .filey .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.filey .custom-control-input[disabled] ~ .custom-control-label::before, .filey .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}
.filey .custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.filey .custom-control-label::before {
  position: absolute;
  top: 0.3rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.filey .custom-control-label::after {
  position: absolute;
  top: 0.3rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}
.filey .custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.filey .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.filey .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #3490dc;
  background-color: #3490dc;
}
.filey .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.filey .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(52, 144, 220, 0.5);
}
.filey .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(52, 144, 220, 0.5);
}
.filey .custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.filey .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.filey .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(52, 144, 220, 0.5);
}
.filey .custom-switch {
  padding-left: 2.25rem;
}
.filey .custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.filey .custom-switch .custom-control-label::after {
  top: calc(0.3rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .filey .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.filey .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.filey .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(52, 144, 220, 0.5);
}
.filey .custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.filey .custom-select:focus {
  border-color: #a1cbef;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}
.filey .custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.filey .custom-select[multiple], .filey .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.filey .custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.filey .custom-select::-ms-expand {
  display: none;
}
.filey .custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.filey .custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}
.filey .custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}
.filey .custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 0.75rem + 2px);
  margin-bottom: 0;
}
.filey .custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.6em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}
.filey .custom-file-input:focus ~ .custom-file-label {
  border-color: #a1cbef;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}
.filey .custom-file-input[disabled] ~ .custom-file-label, .filey .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.filey .custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.filey .custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}
.filey .custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.6em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.filey .custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.6em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.6;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}
.filey .custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.filey .custom-range:focus {
  outline: none;
}
.filey .custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}
.filey .custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}
.filey .custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}
.filey .custom-range::-moz-focus-outer {
  border: 0;
}
.filey .custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #3490dc;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .filey .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.filey .custom-range::-webkit-slider-thumb:active {
  background-color: #cce3f6;
}
.filey .custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.filey .custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #3490dc;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .filey .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.filey .custom-range::-moz-range-thumb:active {
  background-color: #cce3f6;
}
.filey .custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.filey .custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #3490dc;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .filey .custom-range::-ms-thumb {
    transition: none;
  }
}
.filey .custom-range::-ms-thumb:active {
  background-color: #cce3f6;
}
.filey .custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.filey .custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.filey .custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.filey .custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.filey .custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.filey .custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.filey .custom-range:disabled::-moz-range-track {
  cursor: default;
}
.filey .custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}
.filey .custom-control-label::before,
.filey .custom-file-label,
.filey .custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .filey .custom-control-label::before,
.filey .custom-file-label,
.filey .custom-select {
    transition: none;
  }
}
.filey .nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.filey .nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.filey .nav-link:hover, .filey .nav-link:focus {
  text-decoration: none;
}
.filey .nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}
.filey .nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.filey .nav-tabs .nav-item {
  margin-bottom: -1px;
}
.filey .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.filey .nav-tabs .nav-link:hover, .filey .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.filey .nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.filey .nav-tabs .nav-link.active,
.filey .nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #f8fafc;
  border-color: #dee2e6 #dee2e6 #f8fafc;
}
.filey .nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.filey .nav-pills .nav-link {
  border-radius: 0.25rem;
}
.filey .nav-pills .nav-link.active,
.filey .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #3490dc;
}
.filey .nav-fill > .nav-link,
.filey .nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}
.filey .nav-justified > .nav-link,
.filey .nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.filey .tab-content > .tab-pane {
  display: none;
}
.filey .tab-content > .active {
  display: block;
}
.filey .navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.filey .navbar .container,
.filey .navbar .container-fluid,
.filey .navbar .container-sm,
.filey .navbar .container-md,
.filey .navbar .container-lg,
.filey .navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.filey .navbar-brand {
  display: inline-block;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.filey .navbar-brand:hover, .filey .navbar-brand:focus {
  text-decoration: none;
}
.filey .navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.filey .navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.filey .navbar-nav .dropdown-menu {
  position: static;
  float: none;
}
.filey .navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.filey .navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
.filey .navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.filey .navbar-toggler:hover, .filey .navbar-toggler:focus {
  text-decoration: none;
}
.filey .navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}
@media (max-width: 575.98px) {
  .filey .navbar-expand-sm > .container,
.filey .navbar-expand-sm > .container-fluid,
.filey .navbar-expand-sm > .container-sm,
.filey .navbar-expand-sm > .container-md,
.filey .navbar-expand-sm > .container-lg,
.filey .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .filey .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .filey .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .filey .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .filey .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .filey .navbar-expand-sm > .container,
.filey .navbar-expand-sm > .container-fluid,
.filey .navbar-expand-sm > .container-sm,
.filey .navbar-expand-sm > .container-md,
.filey .navbar-expand-sm > .container-lg,
.filey .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .filey .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .filey .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .filey .navbar-expand-md > .container,
.filey .navbar-expand-md > .container-fluid,
.filey .navbar-expand-md > .container-sm,
.filey .navbar-expand-md > .container-md,
.filey .navbar-expand-md > .container-lg,
.filey .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .filey .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .filey .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .filey .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .filey .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .filey .navbar-expand-md > .container,
.filey .navbar-expand-md > .container-fluid,
.filey .navbar-expand-md > .container-sm,
.filey .navbar-expand-md > .container-md,
.filey .navbar-expand-md > .container-lg,
.filey .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .filey .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .filey .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .filey .navbar-expand-lg > .container,
.filey .navbar-expand-lg > .container-fluid,
.filey .navbar-expand-lg > .container-sm,
.filey .navbar-expand-lg > .container-md,
.filey .navbar-expand-lg > .container-lg,
.filey .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .filey .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .filey .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .filey .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .filey .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .filey .navbar-expand-lg > .container,
.filey .navbar-expand-lg > .container-fluid,
.filey .navbar-expand-lg > .container-sm,
.filey .navbar-expand-lg > .container-md,
.filey .navbar-expand-lg > .container-lg,
.filey .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .filey .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .filey .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .filey .navbar-expand-xl > .container,
.filey .navbar-expand-xl > .container-fluid,
.filey .navbar-expand-xl > .container-sm,
.filey .navbar-expand-xl > .container-md,
.filey .navbar-expand-xl > .container-lg,
.filey .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .filey .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .filey .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .filey .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .filey .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .filey .navbar-expand-xl > .container,
.filey .navbar-expand-xl > .container-fluid,
.filey .navbar-expand-xl > .container-sm,
.filey .navbar-expand-xl > .container-md,
.filey .navbar-expand-xl > .container-lg,
.filey .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .filey .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .filey .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.filey .navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.filey .navbar-expand > .container,
.filey .navbar-expand > .container-fluid,
.filey .navbar-expand > .container-sm,
.filey .navbar-expand > .container-md,
.filey .navbar-expand > .container-lg,
.filey .navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.filey .navbar-expand .navbar-nav {
  flex-direction: row;
}
.filey .navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.filey .navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.filey .navbar-expand > .container,
.filey .navbar-expand > .container-fluid,
.filey .navbar-expand > .container-sm,
.filey .navbar-expand > .container-md,
.filey .navbar-expand > .container-lg,
.filey .navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.filey .navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.filey .navbar-expand .navbar-toggler {
  display: none;
}
.filey .navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.filey .navbar-light .navbar-brand:hover, .filey .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.filey .navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.filey .navbar-light .navbar-nav .nav-link:hover, .filey .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.filey .navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.filey .navbar-light .navbar-nav .show > .nav-link,
.filey .navbar-light .navbar-nav .active > .nav-link,
.filey .navbar-light .navbar-nav .nav-link.show,
.filey .navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.filey .navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.filey .navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.filey .navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.filey .navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.filey .navbar-light .navbar-text a:hover, .filey .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}
.filey .navbar-dark .navbar-brand {
  color: #fff;
}
.filey .navbar-dark .navbar-brand:hover, .filey .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.filey .navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.filey .navbar-dark .navbar-nav .nav-link:hover, .filey .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.filey .navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.filey .navbar-dark .navbar-nav .show > .nav-link,
.filey .navbar-dark .navbar-nav .active > .nav-link,
.filey .navbar-dark .navbar-nav .nav-link.show,
.filey .navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.filey .navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.filey .navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.filey .navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.filey .navbar-dark .navbar-text a {
  color: #fff;
}
.filey .navbar-dark .navbar-text a:hover, .filey .navbar-dark .navbar-text a:focus {
  color: #fff;
}
.filey .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.filey .card > hr {
  margin-right: 0;
  margin-left: 0;
}
.filey .card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.filey .card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.filey .card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.filey .card > .card-header + .list-group,
.filey .card > .list-group + .card-footer {
  border-top: 0;
}
.filey .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
.filey .card-title {
  margin-bottom: 0.75rem;
}
.filey .card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}
.filey .card-text:last-child {
  margin-bottom: 0;
}
.filey .card-link:hover {
  text-decoration: none;
}
.filey .card-link + .card-link {
  margin-left: 1.25rem;
}
.filey .card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.filey .card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.filey .card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.filey .card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.filey .card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}
.filey .card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
.filey .card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}
.filey .card-img,
.filey .card-img-top,
.filey .card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}
.filey .card-img,
.filey .card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.filey .card-img,
.filey .card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.filey .card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .filey .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .filey .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}
.filey .card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .filey .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .filey .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .filey .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .filey .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .filey .card-group > .card:not(:last-child) .card-img-top,
.filey .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .filey .card-group > .card:not(:last-child) .card-img-bottom,
.filey .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .filey .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .filey .card-group > .card:not(:first-child) .card-img-top,
.filey .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .filey .card-group > .card:not(:first-child) .card-img-bottom,
.filey .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}
.filey .card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .filey .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .filey .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.filey .accordion {
  overflow-anchor: none;
}
.filey .accordion > .card {
  overflow: hidden;
}
.filey .accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.filey .accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.filey .accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}
.filey .breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.filey .breadcrumb-item {
  display: flex;
}
.filey .breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.filey .breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.filey .breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.filey .breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.filey .breadcrumb-item.active {
  color: #6c757d;
}
.filey .pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
.filey .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #3490dc;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.filey .page-link:hover {
  z-index: 2;
  color: #1d68a7;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.filey .page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}
.filey .page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.filey .page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.filey .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #3490dc;
  border-color: #3490dc;
}
.filey .page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.filey .pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.filey .pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.filey .pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.filey .pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.filey .pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.filey .pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.filey .badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .filey .badge {
    transition: none;
  }
}
a.filey .badge:hover, a.filey .badge:focus {
  text-decoration: none;
}

.filey .badge:empty {
  display: none;
}
.filey .btn .badge {
  position: relative;
  top: -1px;
}
.filey .badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.filey .badge-primary {
  color: #fff;
  background-color: #3490dc;
}
a.filey .badge-primary:hover, a.filey .badge-primary:focus {
  color: #fff;
  background-color: #2176bd;
}
a.filey .badge-primary:focus, a.filey .badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.5);
}

.filey .badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.filey .badge-secondary:hover, a.filey .badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.filey .badge-secondary:focus, a.filey .badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.filey .badge-success {
  color: #fff;
  background-color: #38c172;
}
a.filey .badge-success:hover, a.filey .badge-success:focus {
  color: #fff;
  background-color: #2d995b;
}
a.filey .badge-success:focus, a.filey .badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.5);
}

.filey .badge-info {
  color: #212529;
  background-color: #6cb2eb;
}
a.filey .badge-info:hover, a.filey .badge-info:focus {
  color: #212529;
  background-color: #3f9ae5;
}
a.filey .badge-info:focus, a.filey .badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 178, 235, 0.5);
}

.filey .badge-warning {
  color: #212529;
  background-color: #ffed4a;
}
a.filey .badge-warning:hover, a.filey .badge-warning:focus {
  color: #212529;
  background-color: #ffe817;
}
a.filey .badge-warning:focus, a.filey .badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 237, 74, 0.5);
}

.filey .badge-danger {
  color: #fff;
  background-color: #e3342f;
}
a.filey .badge-danger:hover, a.filey .badge-danger:focus {
  color: #fff;
  background-color: #c51f1a;
}
a.filey .badge-danger:focus, a.filey .badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.5);
}

.filey .badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.filey .badge-light:hover, a.filey .badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.filey .badge-light:focus, a.filey .badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.filey .badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.filey .badge-dark:hover, a.filey .badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.filey .badge-dark:focus, a.filey .badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.filey .jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .filey .jumbotron {
    padding: 4rem 2rem;
  }
}
.filey .jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.filey .alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.filey .alert-heading {
  color: inherit;
}
.filey .alert-link {
  font-weight: 700;
}
.filey .alert-dismissible {
  padding-right: 4rem;
}
.filey .alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
.filey .alert-primary {
  color: #1b4b72;
  background-color: #d6e9f8;
  border-color: #c6e0f5;
}
.filey .alert-primary hr {
  border-top-color: #b0d4f1;
}
.filey .alert-primary .alert-link {
  color: #113049;
}
.filey .alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.filey .alert-secondary hr {
  border-top-color: #c8cbcf;
}
.filey .alert-secondary .alert-link {
  color: #202326;
}
.filey .alert-success {
  color: #1d643b;
  background-color: #d7f3e3;
  border-color: #c7eed8;
}
.filey .alert-success hr {
  border-top-color: #b3e8ca;
}
.filey .alert-success .alert-link {
  color: #123c24;
}
.filey .alert-info {
  color: #385d7a;
  background-color: #e2f0fb;
  border-color: #d6e9f9;
}
.filey .alert-info hr {
  border-top-color: #c0ddf6;
}
.filey .alert-info .alert-link {
  color: #284257;
}
.filey .alert-warning {
  color: #857b26;
  background-color: #fffbdb;
  border-color: #fffacc;
}
.filey .alert-warning hr {
  border-top-color: #fff8b3;
}
.filey .alert-warning .alert-link {
  color: #5d561b;
}
.filey .alert-danger {
  color: #761b18;
  background-color: #f9d6d5;
  border-color: #f7c6c5;
}
.filey .alert-danger hr {
  border-top-color: #f4b0af;
}
.filey .alert-danger .alert-link {
  color: #4c110f;
}
.filey .alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.filey .alert-light hr {
  border-top-color: #ececf6;
}
.filey .alert-light .alert-link {
  color: #686868;
}
.filey .alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.filey .alert-dark hr {
  border-top-color: #b9bbbe;
}
.filey .alert-dark .alert-link {
  color: #040505;
}
@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.filey .progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.filey .progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #3490dc;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .filey .progress-bar {
    transition: none;
  }
}
.filey .progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}
.filey .progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .filey .progress-bar-animated {
    animation: none;
  }
}
.filey .media {
  display: flex;
  align-items: flex-start;
}
.filey .media-body {
  flex: 1;
}
.filey .list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}
.filey .list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.filey .list-group-item-action:hover, .filey .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.filey .list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}
.filey .list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.filey .list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.filey .list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.filey .list-group-item.disabled, .filey .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.filey .list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #3490dc;
  border-color: #3490dc;
}
.filey .list-group-item + .filey .list-group-item {
  border-top-width: 0;
}
.filey .list-group-item + .filey .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}
.filey .list-group-horizontal {
  flex-direction: row;
}
.filey .list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.filey .list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.filey .list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.filey .list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.filey .list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}
@media (min-width: 576px) {
  .filey .list-group-horizontal-sm {
    flex-direction: row;
  }
  .filey .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .filey .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .filey .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .filey .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .filey .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .filey .list-group-horizontal-md {
    flex-direction: row;
  }
  .filey .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .filey .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .filey .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .filey .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .filey .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .filey .list-group-horizontal-lg {
    flex-direction: row;
  }
  .filey .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .filey .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .filey .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .filey .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .filey .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .filey .list-group-horizontal-xl {
    flex-direction: row;
  }
  .filey .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .filey .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .filey .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .filey .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .filey .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.filey .list-group-flush {
  border-radius: 0;
}
.filey .list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.filey .list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}
.filey .list-group-item-primary {
  color: #1b4b72;
  background-color: #c6e0f5;
}
.filey .list-group-item-primary.list-group-item-action:hover, .filey .list-group-item-primary.list-group-item-action:focus {
  color: #1b4b72;
  background-color: #b0d4f1;
}
.filey .list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #1b4b72;
  border-color: #1b4b72;
}
.filey .list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.filey .list-group-item-secondary.list-group-item-action:hover, .filey .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.filey .list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}
.filey .list-group-item-success {
  color: #1d643b;
  background-color: #c7eed8;
}
.filey .list-group-item-success.list-group-item-action:hover, .filey .list-group-item-success.list-group-item-action:focus {
  color: #1d643b;
  background-color: #b3e8ca;
}
.filey .list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #1d643b;
  border-color: #1d643b;
}
.filey .list-group-item-info {
  color: #385d7a;
  background-color: #d6e9f9;
}
.filey .list-group-item-info.list-group-item-action:hover, .filey .list-group-item-info.list-group-item-action:focus {
  color: #385d7a;
  background-color: #c0ddf6;
}
.filey .list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #385d7a;
  border-color: #385d7a;
}
.filey .list-group-item-warning {
  color: #857b26;
  background-color: #fffacc;
}
.filey .list-group-item-warning.list-group-item-action:hover, .filey .list-group-item-warning.list-group-item-action:focus {
  color: #857b26;
  background-color: #fff8b3;
}
.filey .list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #857b26;
  border-color: #857b26;
}
.filey .list-group-item-danger {
  color: #761b18;
  background-color: #f7c6c5;
}
.filey .list-group-item-danger.list-group-item-action:hover, .filey .list-group-item-danger.list-group-item-action:focus {
  color: #761b18;
  background-color: #f4b0af;
}
.filey .list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #761b18;
  border-color: #761b18;
}
.filey .list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.filey .list-group-item-light.list-group-item-action:hover, .filey .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.filey .list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}
.filey .list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.filey .list-group-item-dark.list-group-item-action:hover, .filey .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.filey .list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}
.filey .close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.filey .close:hover {
  color: #000;
  text-decoration: none;
}
.filey .close:not(:disabled):not(.disabled):hover, .filey .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}
.filey button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}
.filey a.close.disabled {
  pointer-events: none;
}
.filey .toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.filey .toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.filey .toast.showing {
  opacity: 1;
}
.filey .toast.show {
  display: block;
  opacity: 1;
}
.filey .toast.hide {
  display: none;
}
.filey .toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.filey .toast-body {
  padding: 0.75rem;
}
.filey .modal-open {
  overflow: hidden;
}
.filey .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.filey .modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.filey .modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .filey .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .filey .modal-dialog {
    transition: none;
  }
}
.modal.show .filey .modal-dialog {
  transform: none;
}
.modal.modal-static .filey .modal-dialog {
  transform: scale(1.02);
}
.filey .modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.filey .modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.filey .modal-dialog-scrollable .modal-header,
.filey .modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.filey .modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.filey .modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.filey .modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.filey .modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.filey .modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.filey .modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}
.filey .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.filey .modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.filey .modal-backdrop.fade {
  opacity: 0;
}
.filey .modal-backdrop.show {
  opacity: 0.5;
}
.filey .modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.filey .modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}
.filey .modal-title {
  margin-bottom: 0;
  line-height: 1.6;
}
.filey .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
.filey .modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.filey .modal-footer > * {
  margin: 0.25rem;
}
.filey .modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .filey .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .filey .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .filey .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .filey .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .filey .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .filey .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .filey .modal-lg,
.filey .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .filey .modal-xl {
    max-width: 1140px;
  }
}
.filey .tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.filey .tooltip.show {
  opacity: 0.9;
}
.filey .tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.filey .tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.filey .bs-tooltip-top, .filey .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.filey .bs-tooltip-top .arrow, .filey .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.filey .bs-tooltip-top .arrow::before, .filey .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}
.filey .bs-tooltip-right, .filey .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.filey .bs-tooltip-right .arrow, .filey .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.filey .bs-tooltip-right .arrow::before, .filey .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}
.filey .bs-tooltip-bottom, .filey .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.filey .bs-tooltip-bottom .arrow, .filey .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.filey .bs-tooltip-bottom .arrow::before, .filey .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}
.filey .bs-tooltip-left, .filey .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.filey .bs-tooltip-left .arrow, .filey .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.filey .bs-tooltip-left .arrow::before, .filey .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}
.filey .tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}
.filey .popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.filey .popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.filey .popover .arrow::before, .filey .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.filey .bs-popover-top, .filey .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.filey .bs-popover-top > .arrow, .filey .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.filey .bs-popover-top > .arrow::before, .filey .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.filey .bs-popover-top > .arrow::after, .filey .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}
.filey .bs-popover-right, .filey .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.filey .bs-popover-right > .arrow, .filey .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.filey .bs-popover-right > .arrow::before, .filey .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.filey .bs-popover-right > .arrow::after, .filey .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}
.filey .bs-popover-bottom, .filey .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.filey .bs-popover-bottom > .arrow, .filey .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.filey .bs-popover-bottom > .arrow::before, .filey .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.filey .bs-popover-bottom > .arrow::after, .filey .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.filey .bs-popover-bottom .popover-header::before, .filey .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}
.filey .bs-popover-left, .filey .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.filey .bs-popover-left > .arrow, .filey .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.filey .bs-popover-left > .arrow::before, .filey .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.filey .bs-popover-left > .arrow::after, .filey .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}
.filey .popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.filey .popover-header:empty {
  display: none;
}
.filey .popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}
.filey .carousel {
  position: relative;
}
.filey .carousel.pointer-event {
  touch-action: pan-y;
}
.filey .carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.filey .carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}
.filey .carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .filey .carousel-item {
    transition: none;
  }
}
.filey .carousel-item.active,
.filey .carousel-item-next,
.filey .carousel-item-prev {
  display: block;
}
.filey .carousel-item-next:not(.carousel-item-left),
.filey .active.carousel-item-right {
  transform: translateX(100%);
}
.filey .carousel-item-prev:not(.carousel-item-right),
.filey .active.carousel-item-left {
  transform: translateX(-100%);
}
.filey .carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.filey .carousel-fade .carousel-item.active,
.filey .carousel-fade .carousel-item-next.carousel-item-left,
.filey .carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.filey .carousel-fade .active.carousel-item-left,
.filey .carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .filey .carousel-fade .active.carousel-item-left,
.filey .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}
.filey .carousel-control-prev,
.filey .carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .filey .carousel-control-prev,
.filey .carousel-control-next {
    transition: none;
  }
}
.filey .carousel-control-prev:hover, .filey .carousel-control-prev:focus,
.filey .carousel-control-next:hover,
.filey .carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.filey .carousel-control-prev {
  left: 0;
}
.filey .carousel-control-next {
  right: 0;
}
.filey .carousel-control-prev-icon,
.filey .carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}
.filey .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}
.filey .carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}
.filey .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.filey .carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .filey .carousel-indicators li {
    transition: none;
  }
}
.filey .carousel-indicators .active {
  opacity: 1;
}
.filey .carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.filey .spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}
.filey .spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.filey .spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}
.filey .spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}
.filey .align-baseline {
  vertical-align: baseline !important;
}
.filey .align-top {
  vertical-align: top !important;
}
.filey .align-middle {
  vertical-align: middle !important;
}
.filey .align-bottom {
  vertical-align: bottom !important;
}
.filey .align-text-bottom {
  vertical-align: text-bottom !important;
}
.filey .align-text-top {
  vertical-align: text-top !important;
}
.filey .bg-primary {
  background-color: #3490dc !important;
}
.filey a.bg-primary:hover, .filey a.bg-primary:focus,
.filey button.bg-primary:hover,
.filey button.bg-primary:focus {
  background-color: #2176bd !important;
}
.filey .bg-secondary {
  background-color: #6c757d !important;
}
.filey a.bg-secondary:hover, .filey a.bg-secondary:focus,
.filey button.bg-secondary:hover,
.filey button.bg-secondary:focus {
  background-color: #545b62 !important;
}
.filey .bg-success {
  background-color: #38c172 !important;
}
.filey a.bg-success:hover, .filey a.bg-success:focus,
.filey button.bg-success:hover,
.filey button.bg-success:focus {
  background-color: #2d995b !important;
}
.filey .bg-info {
  background-color: #6cb2eb !important;
}
.filey a.bg-info:hover, .filey a.bg-info:focus,
.filey button.bg-info:hover,
.filey button.bg-info:focus {
  background-color: #3f9ae5 !important;
}
.filey .bg-warning {
  background-color: #ffed4a !important;
}
.filey a.bg-warning:hover, .filey a.bg-warning:focus,
.filey button.bg-warning:hover,
.filey button.bg-warning:focus {
  background-color: #ffe817 !important;
}
.filey .bg-danger {
  background-color: #e3342f !important;
}
.filey a.bg-danger:hover, .filey a.bg-danger:focus,
.filey button.bg-danger:hover,
.filey button.bg-danger:focus {
  background-color: #c51f1a !important;
}
.filey .bg-light {
  background-color: #f8f9fa !important;
}
.filey a.bg-light:hover, .filey a.bg-light:focus,
.filey button.bg-light:hover,
.filey button.bg-light:focus {
  background-color: #dae0e5 !important;
}
.filey .bg-dark {
  background-color: #343a40 !important;
}
.filey a.bg-dark:hover, .filey a.bg-dark:focus,
.filey button.bg-dark:hover,
.filey button.bg-dark:focus {
  background-color: #1d2124 !important;
}
.filey .bg-white {
  background-color: #fff !important;
}
.filey .bg-transparent {
  background-color: transparent !important;
}
.filey .border {
  border: 1px solid #dee2e6 !important;
}
.filey .border-top {
  border-top: 1px solid #dee2e6 !important;
}
.filey .border-right {
  border-right: 1px solid #dee2e6 !important;
}
.filey .border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.filey .border-left {
  border-left: 1px solid #dee2e6 !important;
}
.filey .border-0 {
  border: 0 !important;
}
.filey .border-top-0 {
  border-top: 0 !important;
}
.filey .border-right-0 {
  border-right: 0 !important;
}
.filey .border-bottom-0 {
  border-bottom: 0 !important;
}
.filey .border-left-0 {
  border-left: 0 !important;
}
.filey .border-primary {
  border-color: #3490dc !important;
}
.filey .border-secondary {
  border-color: #6c757d !important;
}
.filey .border-success {
  border-color: #38c172 !important;
}
.filey .border-info {
  border-color: #6cb2eb !important;
}
.filey .border-warning {
  border-color: #ffed4a !important;
}
.filey .border-danger {
  border-color: #e3342f !important;
}
.filey .border-light {
  border-color: #f8f9fa !important;
}
.filey .border-dark {
  border-color: #343a40 !important;
}
.filey .border-white {
  border-color: #fff !important;
}
.filey .rounded-sm {
  border-radius: 0.2rem !important;
}
.filey .rounded {
  border-radius: 0.25rem !important;
}
.filey .rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.filey .rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.filey .rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.filey .rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.filey .rounded-lg {
  border-radius: 0.3rem !important;
}
.filey .rounded-circle {
  border-radius: 50% !important;
}
.filey .rounded-pill {
  border-radius: 50rem !important;
}
.filey .rounded-0 {
  border-radius: 0 !important;
}
.filey .clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.filey .d-none {
  display: none !important;
}
.filey .d-inline {
  display: inline !important;
}
.filey .d-inline-block {
  display: inline-block !important;
}
.filey .d-block {
  display: block !important;
}
.filey .d-table {
  display: table !important;
}
.filey .d-table-row {
  display: table-row !important;
}
.filey .d-table-cell {
  display: table-cell !important;
}
.filey .d-flex {
  display: flex !important;
}
.filey .d-inline-flex {
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .filey .d-sm-none {
    display: none !important;
  }
  .filey .d-sm-inline {
    display: inline !important;
  }
  .filey .d-sm-inline-block {
    display: inline-block !important;
  }
  .filey .d-sm-block {
    display: block !important;
  }
  .filey .d-sm-table {
    display: table !important;
  }
  .filey .d-sm-table-row {
    display: table-row !important;
  }
  .filey .d-sm-table-cell {
    display: table-cell !important;
  }
  .filey .d-sm-flex {
    display: flex !important;
  }
  .filey .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .filey .d-md-none {
    display: none !important;
  }
  .filey .d-md-inline {
    display: inline !important;
  }
  .filey .d-md-inline-block {
    display: inline-block !important;
  }
  .filey .d-md-block {
    display: block !important;
  }
  .filey .d-md-table {
    display: table !important;
  }
  .filey .d-md-table-row {
    display: table-row !important;
  }
  .filey .d-md-table-cell {
    display: table-cell !important;
  }
  .filey .d-md-flex {
    display: flex !important;
  }
  .filey .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .filey .d-lg-none {
    display: none !important;
  }
  .filey .d-lg-inline {
    display: inline !important;
  }
  .filey .d-lg-inline-block {
    display: inline-block !important;
  }
  .filey .d-lg-block {
    display: block !important;
  }
  .filey .d-lg-table {
    display: table !important;
  }
  .filey .d-lg-table-row {
    display: table-row !important;
  }
  .filey .d-lg-table-cell {
    display: table-cell !important;
  }
  .filey .d-lg-flex {
    display: flex !important;
  }
  .filey .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .filey .d-xl-none {
    display: none !important;
  }
  .filey .d-xl-inline {
    display: inline !important;
  }
  .filey .d-xl-inline-block {
    display: inline-block !important;
  }
  .filey .d-xl-block {
    display: block !important;
  }
  .filey .d-xl-table {
    display: table !important;
  }
  .filey .d-xl-table-row {
    display: table-row !important;
  }
  .filey .d-xl-table-cell {
    display: table-cell !important;
  }
  .filey .d-xl-flex {
    display: flex !important;
  }
  .filey .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .filey .d-print-none {
    display: none !important;
  }
  .filey .d-print-inline {
    display: inline !important;
  }
  .filey .d-print-inline-block {
    display: inline-block !important;
  }
  .filey .d-print-block {
    display: block !important;
  }
  .filey .d-print-table {
    display: table !important;
  }
  .filey .d-print-table-row {
    display: table-row !important;
  }
  .filey .d-print-table-cell {
    display: table-cell !important;
  }
  .filey .d-print-flex {
    display: flex !important;
  }
  .filey .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.filey .embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.filey .embed-responsive::before {
  display: block;
  content: "";
}
.filey .embed-responsive .embed-responsive-item,
.filey .embed-responsive iframe,
.filey .embed-responsive embed,
.filey .embed-responsive object,
.filey .embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.filey .embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}
.filey .embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.filey .embed-responsive-4by3::before {
  padding-top: 75%;
}
.filey .embed-responsive-1by1::before {
  padding-top: 100%;
}
.filey .flex-row {
  flex-direction: row !important;
}
.filey .flex-column {
  flex-direction: column !important;
}
.filey .flex-row-reverse {
  flex-direction: row-reverse !important;
}
.filey .flex-column-reverse {
  flex-direction: column-reverse !important;
}
.filey .flex-wrap {
  flex-wrap: wrap !important;
}
.filey .flex-nowrap {
  flex-wrap: nowrap !important;
}
.filey .flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.filey .flex-fill {
  flex: 1 1 auto !important;
}
.filey .flex-grow-0 {
  flex-grow: 0 !important;
}
.filey .flex-grow-1 {
  flex-grow: 1 !important;
}
.filey .flex-shrink-0 {
  flex-shrink: 0 !important;
}
.filey .flex-shrink-1 {
  flex-shrink: 1 !important;
}
.filey .justify-content-start {
  justify-content: flex-start !important;
}
.filey .justify-content-end {
  justify-content: flex-end !important;
}
.filey .justify-content-center {
  justify-content: center !important;
}
.filey .justify-content-between {
  justify-content: space-between !important;
}
.filey .justify-content-around {
  justify-content: space-around !important;
}
.filey .align-items-start {
  align-items: flex-start !important;
}
.filey .align-items-end {
  align-items: flex-end !important;
}
.filey .align-items-center {
  align-items: center !important;
}
.filey .align-items-baseline {
  align-items: baseline !important;
}
.filey .align-items-stretch {
  align-items: stretch !important;
}
.filey .align-content-start {
  align-content: flex-start !important;
}
.filey .align-content-end {
  align-content: flex-end !important;
}
.filey .align-content-center {
  align-content: center !important;
}
.filey .align-content-between {
  align-content: space-between !important;
}
.filey .align-content-around {
  align-content: space-around !important;
}
.filey .align-content-stretch {
  align-content: stretch !important;
}
.filey .align-self-auto {
  align-self: auto !important;
}
.filey .align-self-start {
  align-self: flex-start !important;
}
.filey .align-self-end {
  align-self: flex-end !important;
}
.filey .align-self-center {
  align-self: center !important;
}
.filey .align-self-baseline {
  align-self: baseline !important;
}
.filey .align-self-stretch {
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .filey .flex-sm-row {
    flex-direction: row !important;
  }
  .filey .flex-sm-column {
    flex-direction: column !important;
  }
  .filey .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .filey .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .filey .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .filey .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .filey .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .filey .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .filey .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .filey .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .filey .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .filey .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .filey .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .filey .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .filey .justify-content-sm-center {
    justify-content: center !important;
  }
  .filey .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .filey .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .filey .align-items-sm-start {
    align-items: flex-start !important;
  }
  .filey .align-items-sm-end {
    align-items: flex-end !important;
  }
  .filey .align-items-sm-center {
    align-items: center !important;
  }
  .filey .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .filey .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .filey .align-content-sm-start {
    align-content: flex-start !important;
  }
  .filey .align-content-sm-end {
    align-content: flex-end !important;
  }
  .filey .align-content-sm-center {
    align-content: center !important;
  }
  .filey .align-content-sm-between {
    align-content: space-between !important;
  }
  .filey .align-content-sm-around {
    align-content: space-around !important;
  }
  .filey .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .filey .align-self-sm-auto {
    align-self: auto !important;
  }
  .filey .align-self-sm-start {
    align-self: flex-start !important;
  }
  .filey .align-self-sm-end {
    align-self: flex-end !important;
  }
  .filey .align-self-sm-center {
    align-self: center !important;
  }
  .filey .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .filey .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .filey .flex-md-row {
    flex-direction: row !important;
  }
  .filey .flex-md-column {
    flex-direction: column !important;
  }
  .filey .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .filey .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .filey .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .filey .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .filey .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .filey .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .filey .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .filey .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .filey .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .filey .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .filey .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .filey .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .filey .justify-content-md-center {
    justify-content: center !important;
  }
  .filey .justify-content-md-between {
    justify-content: space-between !important;
  }
  .filey .justify-content-md-around {
    justify-content: space-around !important;
  }
  .filey .align-items-md-start {
    align-items: flex-start !important;
  }
  .filey .align-items-md-end {
    align-items: flex-end !important;
  }
  .filey .align-items-md-center {
    align-items: center !important;
  }
  .filey .align-items-md-baseline {
    align-items: baseline !important;
  }
  .filey .align-items-md-stretch {
    align-items: stretch !important;
  }
  .filey .align-content-md-start {
    align-content: flex-start !important;
  }
  .filey .align-content-md-end {
    align-content: flex-end !important;
  }
  .filey .align-content-md-center {
    align-content: center !important;
  }
  .filey .align-content-md-between {
    align-content: space-between !important;
  }
  .filey .align-content-md-around {
    align-content: space-around !important;
  }
  .filey .align-content-md-stretch {
    align-content: stretch !important;
  }
  .filey .align-self-md-auto {
    align-self: auto !important;
  }
  .filey .align-self-md-start {
    align-self: flex-start !important;
  }
  .filey .align-self-md-end {
    align-self: flex-end !important;
  }
  .filey .align-self-md-center {
    align-self: center !important;
  }
  .filey .align-self-md-baseline {
    align-self: baseline !important;
  }
  .filey .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .filey .flex-lg-row {
    flex-direction: row !important;
  }
  .filey .flex-lg-column {
    flex-direction: column !important;
  }
  .filey .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .filey .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .filey .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .filey .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .filey .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .filey .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .filey .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .filey .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .filey .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .filey .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .filey .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .filey .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .filey .justify-content-lg-center {
    justify-content: center !important;
  }
  .filey .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .filey .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .filey .align-items-lg-start {
    align-items: flex-start !important;
  }
  .filey .align-items-lg-end {
    align-items: flex-end !important;
  }
  .filey .align-items-lg-center {
    align-items: center !important;
  }
  .filey .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .filey .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .filey .align-content-lg-start {
    align-content: flex-start !important;
  }
  .filey .align-content-lg-end {
    align-content: flex-end !important;
  }
  .filey .align-content-lg-center {
    align-content: center !important;
  }
  .filey .align-content-lg-between {
    align-content: space-between !important;
  }
  .filey .align-content-lg-around {
    align-content: space-around !important;
  }
  .filey .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .filey .align-self-lg-auto {
    align-self: auto !important;
  }
  .filey .align-self-lg-start {
    align-self: flex-start !important;
  }
  .filey .align-self-lg-end {
    align-self: flex-end !important;
  }
  .filey .align-self-lg-center {
    align-self: center !important;
  }
  .filey .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .filey .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .filey .flex-xl-row {
    flex-direction: row !important;
  }
  .filey .flex-xl-column {
    flex-direction: column !important;
  }
  .filey .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .filey .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .filey .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .filey .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .filey .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .filey .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .filey .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .filey .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .filey .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .filey .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .filey .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .filey .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .filey .justify-content-xl-center {
    justify-content: center !important;
  }
  .filey .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .filey .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .filey .align-items-xl-start {
    align-items: flex-start !important;
  }
  .filey .align-items-xl-end {
    align-items: flex-end !important;
  }
  .filey .align-items-xl-center {
    align-items: center !important;
  }
  .filey .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .filey .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .filey .align-content-xl-start {
    align-content: flex-start !important;
  }
  .filey .align-content-xl-end {
    align-content: flex-end !important;
  }
  .filey .align-content-xl-center {
    align-content: center !important;
  }
  .filey .align-content-xl-between {
    align-content: space-between !important;
  }
  .filey .align-content-xl-around {
    align-content: space-around !important;
  }
  .filey .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .filey .align-self-xl-auto {
    align-self: auto !important;
  }
  .filey .align-self-xl-start {
    align-self: flex-start !important;
  }
  .filey .align-self-xl-end {
    align-self: flex-end !important;
  }
  .filey .align-self-xl-center {
    align-self: center !important;
  }
  .filey .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .filey .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.filey .float-left {
  float: left !important;
}
.filey .float-right {
  float: right !important;
}
.filey .float-none {
  float: none !important;
}
@media (min-width: 576px) {
  .filey .float-sm-left {
    float: left !important;
  }
  .filey .float-sm-right {
    float: right !important;
  }
  .filey .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .filey .float-md-left {
    float: left !important;
  }
  .filey .float-md-right {
    float: right !important;
  }
  .filey .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .filey .float-lg-left {
    float: left !important;
  }
  .filey .float-lg-right {
    float: right !important;
  }
  .filey .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .filey .float-xl-left {
    float: left !important;
  }
  .filey .float-xl-right {
    float: right !important;
  }
  .filey .float-xl-none {
    float: none !important;
  }
}
.filey .user-select-all {
  user-select: all !important;
}
.filey .user-select-auto {
  user-select: auto !important;
}
.filey .user-select-none {
  user-select: none !important;
}
.filey .overflow-auto {
  overflow: auto !important;
}
.filey .overflow-hidden {
  overflow: hidden !important;
}
.filey .position-static {
  position: static !important;
}
.filey .position-relative {
  position: relative !important;
}
.filey .position-absolute {
  position: absolute !important;
}
.filey .position-fixed {
  position: fixed !important;
}
.filey .position-sticky {
  position: sticky !important;
}
.filey .fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.filey .fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports (position: sticky) {
  .filey .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.filey .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.filey .sr-only-focusable:active, .filey .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.filey .shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.filey .shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.filey .shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.filey .shadow-none {
  box-shadow: none !important;
}
.filey .w-25 {
  width: 25% !important;
}
.filey .w-50 {
  width: 50% !important;
}
.filey .w-75 {
  width: 75% !important;
}
.filey .w-100 {
  width: 100% !important;
}
.filey .w-auto {
  width: auto !important;
}
.filey .h-25 {
  height: 25% !important;
}
.filey .h-50 {
  height: 50% !important;
}
.filey .h-75 {
  height: 75% !important;
}
.filey .h-100 {
  height: 100% !important;
}
.filey .h-auto {
  height: auto !important;
}
.filey .mw-100 {
  max-width: 100% !important;
}
.filey .mh-100 {
  max-height: 100% !important;
}
.filey .min-vw-100 {
  min-width: 100vw !important;
}
.filey .min-vh-100 {
  min-height: 100vh !important;
}
.filey .vw-100 {
  width: 100vw !important;
}
.filey .vh-100 {
  height: 100vh !important;
}
.filey .m-0 {
  margin: 0 !important;
}
.filey .mt-0,
.filey .my-0 {
  margin-top: 0 !important;
}
.filey .mr-0,
.filey .mx-0 {
  margin-right: 0 !important;
}
.filey .mb-0,
.filey .my-0 {
  margin-bottom: 0 !important;
}
.filey .ml-0,
.filey .mx-0 {
  margin-left: 0 !important;
}
.filey .m-1 {
  margin: 0.25rem !important;
}
.filey .mt-1,
.filey .my-1 {
  margin-top: 0.25rem !important;
}
.filey .mr-1,
.filey .mx-1 {
  margin-right: 0.25rem !important;
}
.filey .mb-1,
.filey .my-1 {
  margin-bottom: 0.25rem !important;
}
.filey .ml-1,
.filey .mx-1 {
  margin-left: 0.25rem !important;
}
.filey .m-2 {
  margin: 0.5rem !important;
}
.filey .mt-2,
.filey .my-2 {
  margin-top: 0.5rem !important;
}
.filey .mr-2,
.filey .mx-2 {
  margin-right: 0.5rem !important;
}
.filey .mb-2,
.filey .my-2 {
  margin-bottom: 0.5rem !important;
}
.filey .ml-2,
.filey .mx-2 {
  margin-left: 0.5rem !important;
}
.filey .m-3 {
  margin: 1rem !important;
}
.filey .mt-3,
.filey .my-3 {
  margin-top: 1rem !important;
}
.filey .mr-3,
.filey .mx-3 {
  margin-right: 1rem !important;
}
.filey .mb-3,
.filey .my-3 {
  margin-bottom: 1rem !important;
}
.filey .ml-3,
.filey .mx-3 {
  margin-left: 1rem !important;
}
.filey .m-4 {
  margin: 1.5rem !important;
}
.filey .mt-4,
.filey .my-4 {
  margin-top: 1.5rem !important;
}
.filey .mr-4,
.filey .mx-4 {
  margin-right: 1.5rem !important;
}
.filey .mb-4,
.filey .my-4 {
  margin-bottom: 1.5rem !important;
}
.filey .ml-4,
.filey .mx-4 {
  margin-left: 1.5rem !important;
}
.filey .m-5 {
  margin: 3rem !important;
}
.filey .mt-5,
.filey .my-5 {
  margin-top: 3rem !important;
}
.filey .mr-5,
.filey .mx-5 {
  margin-right: 3rem !important;
}
.filey .mb-5,
.filey .my-5 {
  margin-bottom: 3rem !important;
}
.filey .ml-5,
.filey .mx-5 {
  margin-left: 3rem !important;
}
.filey .p-0 {
  padding: 0 !important;
}
.filey .pt-0,
.filey .py-0 {
  padding-top: 0 !important;
}
.filey .pr-0,
.filey .px-0 {
  padding-right: 0 !important;
}
.filey .pb-0,
.filey .py-0 {
  padding-bottom: 0 !important;
}
.filey .pl-0,
.filey .px-0 {
  padding-left: 0 !important;
}
.filey .p-1 {
  padding: 0.25rem !important;
}
.filey .pt-1,
.filey .py-1 {
  padding-top: 0.25rem !important;
}
.filey .pr-1,
.filey .px-1 {
  padding-right: 0.25rem !important;
}
.filey .pb-1,
.filey .py-1 {
  padding-bottom: 0.25rem !important;
}
.filey .pl-1,
.filey .px-1 {
  padding-left: 0.25rem !important;
}
.filey .p-2 {
  padding: 0.5rem !important;
}
.filey .pt-2,
.filey .py-2 {
  padding-top: 0.5rem !important;
}
.filey .pr-2,
.filey .px-2 {
  padding-right: 0.5rem !important;
}
.filey .pb-2,
.filey .py-2 {
  padding-bottom: 0.5rem !important;
}
.filey .pl-2,
.filey .px-2 {
  padding-left: 0.5rem !important;
}
.filey .p-3 {
  padding: 1rem !important;
}
.filey .pt-3,
.filey .py-3 {
  padding-top: 1rem !important;
}
.filey .pr-3,
.filey .px-3 {
  padding-right: 1rem !important;
}
.filey .pb-3,
.filey .py-3 {
  padding-bottom: 1rem !important;
}
.filey .pl-3,
.filey .px-3 {
  padding-left: 1rem !important;
}
.filey .p-4 {
  padding: 1.5rem !important;
}
.filey .pt-4,
.filey .py-4 {
  padding-top: 1.5rem !important;
}
.filey .pr-4,
.filey .px-4 {
  padding-right: 1.5rem !important;
}
.filey .pb-4,
.filey .py-4 {
  padding-bottom: 1.5rem !important;
}
.filey .pl-4,
.filey .px-4 {
  padding-left: 1.5rem !important;
}
.filey .p-5 {
  padding: 3rem !important;
}
.filey .pt-5,
.filey .py-5 {
  padding-top: 3rem !important;
}
.filey .pr-5,
.filey .px-5 {
  padding-right: 3rem !important;
}
.filey .pb-5,
.filey .py-5 {
  padding-bottom: 3rem !important;
}
.filey .pl-5,
.filey .px-5 {
  padding-left: 3rem !important;
}
.filey .m-n1 {
  margin: -0.25rem !important;
}
.filey .mt-n1,
.filey .my-n1 {
  margin-top: -0.25rem !important;
}
.filey .mr-n1,
.filey .mx-n1 {
  margin-right: -0.25rem !important;
}
.filey .mb-n1,
.filey .my-n1 {
  margin-bottom: -0.25rem !important;
}
.filey .ml-n1,
.filey .mx-n1 {
  margin-left: -0.25rem !important;
}
.filey .m-n2 {
  margin: -0.5rem !important;
}
.filey .mt-n2,
.filey .my-n2 {
  margin-top: -0.5rem !important;
}
.filey .mr-n2,
.filey .mx-n2 {
  margin-right: -0.5rem !important;
}
.filey .mb-n2,
.filey .my-n2 {
  margin-bottom: -0.5rem !important;
}
.filey .ml-n2,
.filey .mx-n2 {
  margin-left: -0.5rem !important;
}
.filey .m-n3 {
  margin: -1rem !important;
}
.filey .mt-n3,
.filey .my-n3 {
  margin-top: -1rem !important;
}
.filey .mr-n3,
.filey .mx-n3 {
  margin-right: -1rem !important;
}
.filey .mb-n3,
.filey .my-n3 {
  margin-bottom: -1rem !important;
}
.filey .ml-n3,
.filey .mx-n3 {
  margin-left: -1rem !important;
}
.filey .m-n4 {
  margin: -1.5rem !important;
}
.filey .mt-n4,
.filey .my-n4 {
  margin-top: -1.5rem !important;
}
.filey .mr-n4,
.filey .mx-n4 {
  margin-right: -1.5rem !important;
}
.filey .mb-n4,
.filey .my-n4 {
  margin-bottom: -1.5rem !important;
}
.filey .ml-n4,
.filey .mx-n4 {
  margin-left: -1.5rem !important;
}
.filey .m-n5 {
  margin: -3rem !important;
}
.filey .mt-n5,
.filey .my-n5 {
  margin-top: -3rem !important;
}
.filey .mr-n5,
.filey .mx-n5 {
  margin-right: -3rem !important;
}
.filey .mb-n5,
.filey .my-n5 {
  margin-bottom: -3rem !important;
}
.filey .ml-n5,
.filey .mx-n5 {
  margin-left: -3rem !important;
}
.filey .m-auto {
  margin: auto !important;
}
.filey .mt-auto,
.filey .my-auto {
  margin-top: auto !important;
}
.filey .mr-auto,
.filey .mx-auto {
  margin-right: auto !important;
}
.filey .mb-auto,
.filey .my-auto {
  margin-bottom: auto !important;
}
.filey .ml-auto,
.filey .mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .filey .m-sm-0 {
    margin: 0 !important;
  }
  .filey .mt-sm-0,
.filey .my-sm-0 {
    margin-top: 0 !important;
  }
  .filey .mr-sm-0,
.filey .mx-sm-0 {
    margin-right: 0 !important;
  }
  .filey .mb-sm-0,
.filey .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .filey .ml-sm-0,
.filey .mx-sm-0 {
    margin-left: 0 !important;
  }
  .filey .m-sm-1 {
    margin: 0.25rem !important;
  }
  .filey .mt-sm-1,
.filey .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .filey .mr-sm-1,
.filey .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .filey .mb-sm-1,
.filey .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .filey .ml-sm-1,
.filey .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .filey .m-sm-2 {
    margin: 0.5rem !important;
  }
  .filey .mt-sm-2,
.filey .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .filey .mr-sm-2,
.filey .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .filey .mb-sm-2,
.filey .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .filey .ml-sm-2,
.filey .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .filey .m-sm-3 {
    margin: 1rem !important;
  }
  .filey .mt-sm-3,
.filey .my-sm-3 {
    margin-top: 1rem !important;
  }
  .filey .mr-sm-3,
.filey .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .filey .mb-sm-3,
.filey .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .filey .ml-sm-3,
.filey .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .filey .m-sm-4 {
    margin: 1.5rem !important;
  }
  .filey .mt-sm-4,
.filey .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .filey .mr-sm-4,
.filey .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .filey .mb-sm-4,
.filey .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .filey .ml-sm-4,
.filey .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .filey .m-sm-5 {
    margin: 3rem !important;
  }
  .filey .mt-sm-5,
.filey .my-sm-5 {
    margin-top: 3rem !important;
  }
  .filey .mr-sm-5,
.filey .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .filey .mb-sm-5,
.filey .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .filey .ml-sm-5,
.filey .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .filey .p-sm-0 {
    padding: 0 !important;
  }
  .filey .pt-sm-0,
.filey .py-sm-0 {
    padding-top: 0 !important;
  }
  .filey .pr-sm-0,
.filey .px-sm-0 {
    padding-right: 0 !important;
  }
  .filey .pb-sm-0,
.filey .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .filey .pl-sm-0,
.filey .px-sm-0 {
    padding-left: 0 !important;
  }
  .filey .p-sm-1 {
    padding: 0.25rem !important;
  }
  .filey .pt-sm-1,
.filey .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .filey .pr-sm-1,
.filey .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .filey .pb-sm-1,
.filey .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .filey .pl-sm-1,
.filey .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .filey .p-sm-2 {
    padding: 0.5rem !important;
  }
  .filey .pt-sm-2,
.filey .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .filey .pr-sm-2,
.filey .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .filey .pb-sm-2,
.filey .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .filey .pl-sm-2,
.filey .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .filey .p-sm-3 {
    padding: 1rem !important;
  }
  .filey .pt-sm-3,
.filey .py-sm-3 {
    padding-top: 1rem !important;
  }
  .filey .pr-sm-3,
.filey .px-sm-3 {
    padding-right: 1rem !important;
  }
  .filey .pb-sm-3,
.filey .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .filey .pl-sm-3,
.filey .px-sm-3 {
    padding-left: 1rem !important;
  }
  .filey .p-sm-4 {
    padding: 1.5rem !important;
  }
  .filey .pt-sm-4,
.filey .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .filey .pr-sm-4,
.filey .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .filey .pb-sm-4,
.filey .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .filey .pl-sm-4,
.filey .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .filey .p-sm-5 {
    padding: 3rem !important;
  }
  .filey .pt-sm-5,
.filey .py-sm-5 {
    padding-top: 3rem !important;
  }
  .filey .pr-sm-5,
.filey .px-sm-5 {
    padding-right: 3rem !important;
  }
  .filey .pb-sm-5,
.filey .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .filey .pl-sm-5,
.filey .px-sm-5 {
    padding-left: 3rem !important;
  }
  .filey .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .filey .mt-sm-n1,
.filey .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .filey .mr-sm-n1,
.filey .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .filey .mb-sm-n1,
.filey .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .filey .ml-sm-n1,
.filey .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .filey .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .filey .mt-sm-n2,
.filey .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .filey .mr-sm-n2,
.filey .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .filey .mb-sm-n2,
.filey .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .filey .ml-sm-n2,
.filey .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .filey .m-sm-n3 {
    margin: -1rem !important;
  }
  .filey .mt-sm-n3,
.filey .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .filey .mr-sm-n3,
.filey .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .filey .mb-sm-n3,
.filey .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .filey .ml-sm-n3,
.filey .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .filey .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .filey .mt-sm-n4,
.filey .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .filey .mr-sm-n4,
.filey .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .filey .mb-sm-n4,
.filey .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .filey .ml-sm-n4,
.filey .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .filey .m-sm-n5 {
    margin: -3rem !important;
  }
  .filey .mt-sm-n5,
.filey .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .filey .mr-sm-n5,
.filey .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .filey .mb-sm-n5,
.filey .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .filey .ml-sm-n5,
.filey .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .filey .m-sm-auto {
    margin: auto !important;
  }
  .filey .mt-sm-auto,
.filey .my-sm-auto {
    margin-top: auto !important;
  }
  .filey .mr-sm-auto,
.filey .mx-sm-auto {
    margin-right: auto !important;
  }
  .filey .mb-sm-auto,
.filey .my-sm-auto {
    margin-bottom: auto !important;
  }
  .filey .ml-sm-auto,
.filey .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .filey .m-md-0 {
    margin: 0 !important;
  }
  .filey .mt-md-0,
.filey .my-md-0 {
    margin-top: 0 !important;
  }
  .filey .mr-md-0,
.filey .mx-md-0 {
    margin-right: 0 !important;
  }
  .filey .mb-md-0,
.filey .my-md-0 {
    margin-bottom: 0 !important;
  }
  .filey .ml-md-0,
.filey .mx-md-0 {
    margin-left: 0 !important;
  }
  .filey .m-md-1 {
    margin: 0.25rem !important;
  }
  .filey .mt-md-1,
.filey .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .filey .mr-md-1,
.filey .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .filey .mb-md-1,
.filey .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .filey .ml-md-1,
.filey .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .filey .m-md-2 {
    margin: 0.5rem !important;
  }
  .filey .mt-md-2,
.filey .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .filey .mr-md-2,
.filey .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .filey .mb-md-2,
.filey .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .filey .ml-md-2,
.filey .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .filey .m-md-3 {
    margin: 1rem !important;
  }
  .filey .mt-md-3,
.filey .my-md-3 {
    margin-top: 1rem !important;
  }
  .filey .mr-md-3,
.filey .mx-md-3 {
    margin-right: 1rem !important;
  }
  .filey .mb-md-3,
.filey .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .filey .ml-md-3,
.filey .mx-md-3 {
    margin-left: 1rem !important;
  }
  .filey .m-md-4 {
    margin: 1.5rem !important;
  }
  .filey .mt-md-4,
.filey .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .filey .mr-md-4,
.filey .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .filey .mb-md-4,
.filey .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .filey .ml-md-4,
.filey .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .filey .m-md-5 {
    margin: 3rem !important;
  }
  .filey .mt-md-5,
.filey .my-md-5 {
    margin-top: 3rem !important;
  }
  .filey .mr-md-5,
.filey .mx-md-5 {
    margin-right: 3rem !important;
  }
  .filey .mb-md-5,
.filey .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .filey .ml-md-5,
.filey .mx-md-5 {
    margin-left: 3rem !important;
  }
  .filey .p-md-0 {
    padding: 0 !important;
  }
  .filey .pt-md-0,
.filey .py-md-0 {
    padding-top: 0 !important;
  }
  .filey .pr-md-0,
.filey .px-md-0 {
    padding-right: 0 !important;
  }
  .filey .pb-md-0,
.filey .py-md-0 {
    padding-bottom: 0 !important;
  }
  .filey .pl-md-0,
.filey .px-md-0 {
    padding-left: 0 !important;
  }
  .filey .p-md-1 {
    padding: 0.25rem !important;
  }
  .filey .pt-md-1,
.filey .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .filey .pr-md-1,
.filey .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .filey .pb-md-1,
.filey .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .filey .pl-md-1,
.filey .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .filey .p-md-2 {
    padding: 0.5rem !important;
  }
  .filey .pt-md-2,
.filey .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .filey .pr-md-2,
.filey .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .filey .pb-md-2,
.filey .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .filey .pl-md-2,
.filey .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .filey .p-md-3 {
    padding: 1rem !important;
  }
  .filey .pt-md-3,
.filey .py-md-3 {
    padding-top: 1rem !important;
  }
  .filey .pr-md-3,
.filey .px-md-3 {
    padding-right: 1rem !important;
  }
  .filey .pb-md-3,
.filey .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .filey .pl-md-3,
.filey .px-md-3 {
    padding-left: 1rem !important;
  }
  .filey .p-md-4 {
    padding: 1.5rem !important;
  }
  .filey .pt-md-4,
.filey .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .filey .pr-md-4,
.filey .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .filey .pb-md-4,
.filey .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .filey .pl-md-4,
.filey .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .filey .p-md-5 {
    padding: 3rem !important;
  }
  .filey .pt-md-5,
.filey .py-md-5 {
    padding-top: 3rem !important;
  }
  .filey .pr-md-5,
.filey .px-md-5 {
    padding-right: 3rem !important;
  }
  .filey .pb-md-5,
.filey .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .filey .pl-md-5,
.filey .px-md-5 {
    padding-left: 3rem !important;
  }
  .filey .m-md-n1 {
    margin: -0.25rem !important;
  }
  .filey .mt-md-n1,
.filey .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .filey .mr-md-n1,
.filey .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .filey .mb-md-n1,
.filey .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .filey .ml-md-n1,
.filey .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .filey .m-md-n2 {
    margin: -0.5rem !important;
  }
  .filey .mt-md-n2,
.filey .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .filey .mr-md-n2,
.filey .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .filey .mb-md-n2,
.filey .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .filey .ml-md-n2,
.filey .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .filey .m-md-n3 {
    margin: -1rem !important;
  }
  .filey .mt-md-n3,
.filey .my-md-n3 {
    margin-top: -1rem !important;
  }
  .filey .mr-md-n3,
.filey .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .filey .mb-md-n3,
.filey .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .filey .ml-md-n3,
.filey .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .filey .m-md-n4 {
    margin: -1.5rem !important;
  }
  .filey .mt-md-n4,
.filey .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .filey .mr-md-n4,
.filey .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .filey .mb-md-n4,
.filey .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .filey .ml-md-n4,
.filey .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .filey .m-md-n5 {
    margin: -3rem !important;
  }
  .filey .mt-md-n5,
.filey .my-md-n5 {
    margin-top: -3rem !important;
  }
  .filey .mr-md-n5,
.filey .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .filey .mb-md-n5,
.filey .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .filey .ml-md-n5,
.filey .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .filey .m-md-auto {
    margin: auto !important;
  }
  .filey .mt-md-auto,
.filey .my-md-auto {
    margin-top: auto !important;
  }
  .filey .mr-md-auto,
.filey .mx-md-auto {
    margin-right: auto !important;
  }
  .filey .mb-md-auto,
.filey .my-md-auto {
    margin-bottom: auto !important;
  }
  .filey .ml-md-auto,
.filey .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .filey .m-lg-0 {
    margin: 0 !important;
  }
  .filey .mt-lg-0,
.filey .my-lg-0 {
    margin-top: 0 !important;
  }
  .filey .mr-lg-0,
.filey .mx-lg-0 {
    margin-right: 0 !important;
  }
  .filey .mb-lg-0,
.filey .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .filey .ml-lg-0,
.filey .mx-lg-0 {
    margin-left: 0 !important;
  }
  .filey .m-lg-1 {
    margin: 0.25rem !important;
  }
  .filey .mt-lg-1,
.filey .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .filey .mr-lg-1,
.filey .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .filey .mb-lg-1,
.filey .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .filey .ml-lg-1,
.filey .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .filey .m-lg-2 {
    margin: 0.5rem !important;
  }
  .filey .mt-lg-2,
.filey .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .filey .mr-lg-2,
.filey .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .filey .mb-lg-2,
.filey .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .filey .ml-lg-2,
.filey .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .filey .m-lg-3 {
    margin: 1rem !important;
  }
  .filey .mt-lg-3,
.filey .my-lg-3 {
    margin-top: 1rem !important;
  }
  .filey .mr-lg-3,
.filey .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .filey .mb-lg-3,
.filey .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .filey .ml-lg-3,
.filey .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .filey .m-lg-4 {
    margin: 1.5rem !important;
  }
  .filey .mt-lg-4,
.filey .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .filey .mr-lg-4,
.filey .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .filey .mb-lg-4,
.filey .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .filey .ml-lg-4,
.filey .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .filey .m-lg-5 {
    margin: 3rem !important;
  }
  .filey .mt-lg-5,
.filey .my-lg-5 {
    margin-top: 3rem !important;
  }
  .filey .mr-lg-5,
.filey .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .filey .mb-lg-5,
.filey .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .filey .ml-lg-5,
.filey .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .filey .p-lg-0 {
    padding: 0 !important;
  }
  .filey .pt-lg-0,
.filey .py-lg-0 {
    padding-top: 0 !important;
  }
  .filey .pr-lg-0,
.filey .px-lg-0 {
    padding-right: 0 !important;
  }
  .filey .pb-lg-0,
.filey .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .filey .pl-lg-0,
.filey .px-lg-0 {
    padding-left: 0 !important;
  }
  .filey .p-lg-1 {
    padding: 0.25rem !important;
  }
  .filey .pt-lg-1,
.filey .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .filey .pr-lg-1,
.filey .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .filey .pb-lg-1,
.filey .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .filey .pl-lg-1,
.filey .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .filey .p-lg-2 {
    padding: 0.5rem !important;
  }
  .filey .pt-lg-2,
.filey .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .filey .pr-lg-2,
.filey .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .filey .pb-lg-2,
.filey .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .filey .pl-lg-2,
.filey .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .filey .p-lg-3 {
    padding: 1rem !important;
  }
  .filey .pt-lg-3,
.filey .py-lg-3 {
    padding-top: 1rem !important;
  }
  .filey .pr-lg-3,
.filey .px-lg-3 {
    padding-right: 1rem !important;
  }
  .filey .pb-lg-3,
.filey .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .filey .pl-lg-3,
.filey .px-lg-3 {
    padding-left: 1rem !important;
  }
  .filey .p-lg-4 {
    padding: 1.5rem !important;
  }
  .filey .pt-lg-4,
.filey .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .filey .pr-lg-4,
.filey .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .filey .pb-lg-4,
.filey .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .filey .pl-lg-4,
.filey .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .filey .p-lg-5 {
    padding: 3rem !important;
  }
  .filey .pt-lg-5,
.filey .py-lg-5 {
    padding-top: 3rem !important;
  }
  .filey .pr-lg-5,
.filey .px-lg-5 {
    padding-right: 3rem !important;
  }
  .filey .pb-lg-5,
.filey .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .filey .pl-lg-5,
.filey .px-lg-5 {
    padding-left: 3rem !important;
  }
  .filey .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .filey .mt-lg-n1,
.filey .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .filey .mr-lg-n1,
.filey .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .filey .mb-lg-n1,
.filey .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .filey .ml-lg-n1,
.filey .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .filey .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .filey .mt-lg-n2,
.filey .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .filey .mr-lg-n2,
.filey .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .filey .mb-lg-n2,
.filey .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .filey .ml-lg-n2,
.filey .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .filey .m-lg-n3 {
    margin: -1rem !important;
  }
  .filey .mt-lg-n3,
.filey .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .filey .mr-lg-n3,
.filey .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .filey .mb-lg-n3,
.filey .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .filey .ml-lg-n3,
.filey .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .filey .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .filey .mt-lg-n4,
.filey .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .filey .mr-lg-n4,
.filey .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .filey .mb-lg-n4,
.filey .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .filey .ml-lg-n4,
.filey .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .filey .m-lg-n5 {
    margin: -3rem !important;
  }
  .filey .mt-lg-n5,
.filey .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .filey .mr-lg-n5,
.filey .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .filey .mb-lg-n5,
.filey .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .filey .ml-lg-n5,
.filey .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .filey .m-lg-auto {
    margin: auto !important;
  }
  .filey .mt-lg-auto,
.filey .my-lg-auto {
    margin-top: auto !important;
  }
  .filey .mr-lg-auto,
.filey .mx-lg-auto {
    margin-right: auto !important;
  }
  .filey .mb-lg-auto,
.filey .my-lg-auto {
    margin-bottom: auto !important;
  }
  .filey .ml-lg-auto,
.filey .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .filey .m-xl-0 {
    margin: 0 !important;
  }
  .filey .mt-xl-0,
.filey .my-xl-0 {
    margin-top: 0 !important;
  }
  .filey .mr-xl-0,
.filey .mx-xl-0 {
    margin-right: 0 !important;
  }
  .filey .mb-xl-0,
.filey .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .filey .ml-xl-0,
.filey .mx-xl-0 {
    margin-left: 0 !important;
  }
  .filey .m-xl-1 {
    margin: 0.25rem !important;
  }
  .filey .mt-xl-1,
.filey .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .filey .mr-xl-1,
.filey .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .filey .mb-xl-1,
.filey .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .filey .ml-xl-1,
.filey .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .filey .m-xl-2 {
    margin: 0.5rem !important;
  }
  .filey .mt-xl-2,
.filey .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .filey .mr-xl-2,
.filey .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .filey .mb-xl-2,
.filey .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .filey .ml-xl-2,
.filey .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .filey .m-xl-3 {
    margin: 1rem !important;
  }
  .filey .mt-xl-3,
.filey .my-xl-3 {
    margin-top: 1rem !important;
  }
  .filey .mr-xl-3,
.filey .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .filey .mb-xl-3,
.filey .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .filey .ml-xl-3,
.filey .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .filey .m-xl-4 {
    margin: 1.5rem !important;
  }
  .filey .mt-xl-4,
.filey .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .filey .mr-xl-4,
.filey .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .filey .mb-xl-4,
.filey .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .filey .ml-xl-4,
.filey .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .filey .m-xl-5 {
    margin: 3rem !important;
  }
  .filey .mt-xl-5,
.filey .my-xl-5 {
    margin-top: 3rem !important;
  }
  .filey .mr-xl-5,
.filey .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .filey .mb-xl-5,
.filey .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .filey .ml-xl-5,
.filey .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .filey .p-xl-0 {
    padding: 0 !important;
  }
  .filey .pt-xl-0,
.filey .py-xl-0 {
    padding-top: 0 !important;
  }
  .filey .pr-xl-0,
.filey .px-xl-0 {
    padding-right: 0 !important;
  }
  .filey .pb-xl-0,
.filey .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .filey .pl-xl-0,
.filey .px-xl-0 {
    padding-left: 0 !important;
  }
  .filey .p-xl-1 {
    padding: 0.25rem !important;
  }
  .filey .pt-xl-1,
.filey .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .filey .pr-xl-1,
.filey .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .filey .pb-xl-1,
.filey .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .filey .pl-xl-1,
.filey .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .filey .p-xl-2 {
    padding: 0.5rem !important;
  }
  .filey .pt-xl-2,
.filey .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .filey .pr-xl-2,
.filey .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .filey .pb-xl-2,
.filey .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .filey .pl-xl-2,
.filey .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .filey .p-xl-3 {
    padding: 1rem !important;
  }
  .filey .pt-xl-3,
.filey .py-xl-3 {
    padding-top: 1rem !important;
  }
  .filey .pr-xl-3,
.filey .px-xl-3 {
    padding-right: 1rem !important;
  }
  .filey .pb-xl-3,
.filey .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .filey .pl-xl-3,
.filey .px-xl-3 {
    padding-left: 1rem !important;
  }
  .filey .p-xl-4 {
    padding: 1.5rem !important;
  }
  .filey .pt-xl-4,
.filey .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .filey .pr-xl-4,
.filey .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .filey .pb-xl-4,
.filey .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .filey .pl-xl-4,
.filey .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .filey .p-xl-5 {
    padding: 3rem !important;
  }
  .filey .pt-xl-5,
.filey .py-xl-5 {
    padding-top: 3rem !important;
  }
  .filey .pr-xl-5,
.filey .px-xl-5 {
    padding-right: 3rem !important;
  }
  .filey .pb-xl-5,
.filey .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .filey .pl-xl-5,
.filey .px-xl-5 {
    padding-left: 3rem !important;
  }
  .filey .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .filey .mt-xl-n1,
.filey .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .filey .mr-xl-n1,
.filey .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .filey .mb-xl-n1,
.filey .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .filey .ml-xl-n1,
.filey .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .filey .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .filey .mt-xl-n2,
.filey .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .filey .mr-xl-n2,
.filey .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .filey .mb-xl-n2,
.filey .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .filey .ml-xl-n2,
.filey .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .filey .m-xl-n3 {
    margin: -1rem !important;
  }
  .filey .mt-xl-n3,
.filey .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .filey .mr-xl-n3,
.filey .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .filey .mb-xl-n3,
.filey .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .filey .ml-xl-n3,
.filey .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .filey .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .filey .mt-xl-n4,
.filey .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .filey .mr-xl-n4,
.filey .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .filey .mb-xl-n4,
.filey .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .filey .ml-xl-n4,
.filey .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .filey .m-xl-n5 {
    margin: -3rem !important;
  }
  .filey .mt-xl-n5,
.filey .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .filey .mr-xl-n5,
.filey .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .filey .mb-xl-n5,
.filey .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .filey .ml-xl-n5,
.filey .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .filey .m-xl-auto {
    margin: auto !important;
  }
  .filey .mt-xl-auto,
.filey .my-xl-auto {
    margin-top: auto !important;
  }
  .filey .mr-xl-auto,
.filey .mx-xl-auto {
    margin-right: auto !important;
  }
  .filey .mb-xl-auto,
.filey .my-xl-auto {
    margin-bottom: auto !important;
  }
  .filey .ml-xl-auto,
.filey .mx-xl-auto {
    margin-left: auto !important;
  }
}
.filey .stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}
.filey .text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}
.filey .text-justify {
  text-align: justify !important;
}
.filey .text-wrap {
  white-space: normal !important;
}
.filey .text-nowrap {
  white-space: nowrap !important;
}
.filey .text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.filey .text-left {
  text-align: left !important;
}
.filey .text-right {
  text-align: right !important;
}
.filey .text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .filey .text-sm-left {
    text-align: left !important;
  }
  .filey .text-sm-right {
    text-align: right !important;
  }
  .filey .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .filey .text-md-left {
    text-align: left !important;
  }
  .filey .text-md-right {
    text-align: right !important;
  }
  .filey .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .filey .text-lg-left {
    text-align: left !important;
  }
  .filey .text-lg-right {
    text-align: right !important;
  }
  .filey .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .filey .text-xl-left {
    text-align: left !important;
  }
  .filey .text-xl-right {
    text-align: right !important;
  }
  .filey .text-xl-center {
    text-align: center !important;
  }
}
.filey .text-lowercase {
  text-transform: lowercase !important;
}
.filey .text-uppercase {
  text-transform: uppercase !important;
}
.filey .text-capitalize {
  text-transform: capitalize !important;
}
.filey .font-weight-light {
  font-weight: 300 !important;
}
.filey .font-weight-lighter {
  font-weight: lighter !important;
}
.filey .font-weight-normal {
  font-weight: 400 !important;
}
.filey .font-weight-bold {
  font-weight: 700 !important;
}
.filey .font-weight-bolder {
  font-weight: bolder !important;
}
.filey .font-italic {
  font-style: italic !important;
}
.filey .text-white {
  color: #fff !important;
}
.filey .text-primary {
  color: #3490dc !important;
}
.filey a.text-primary:hover, .filey a.text-primary:focus {
  color: #1d68a7 !important;
}
.filey .text-secondary {
  color: #6c757d !important;
}
.filey a.text-secondary:hover, .filey a.text-secondary:focus {
  color: #494f54 !important;
}
.filey .text-success {
  color: #38c172 !important;
}
.filey a.text-success:hover, .filey a.text-success:focus {
  color: #27864f !important;
}
.filey .text-info {
  color: #6cb2eb !important;
}
.filey a.text-info:hover, .filey a.text-info:focus {
  color: #298fe2 !important;
}
.filey .text-warning {
  color: #ffed4a !important;
}
.filey a.text-warning:hover, .filey a.text-warning:focus {
  color: #fde300 !important;
}
.filey .text-danger {
  color: #e3342f !important;
}
.filey a.text-danger:hover, .filey a.text-danger:focus {
  color: #ae1c17 !important;
}
.filey .text-light {
  color: #f8f9fa !important;
}
.filey a.text-light:hover, .filey a.text-light:focus {
  color: #cbd3da !important;
}
.filey .text-dark {
  color: #343a40 !important;
}
.filey a.text-dark:hover, .filey a.text-dark:focus {
  color: #121416 !important;
}
.filey .text-body {
  color: #212529 !important;
}
.filey .text-muted {
  color: #6c757d !important;
}
.filey .text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.filey .text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.filey .text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.filey .text-decoration-none {
  text-decoration: none !important;
}
.filey .text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}
.filey .text-reset {
  color: inherit !important;
}
.filey .visible {
  visibility: visible !important;
}
.filey .invisible {
  visibility: hidden !important;
}
.filey :root {
  --blue: #3490dc;
  --indigo: #6574cd;
  --purple: #9561e2;
  --pink: #f66d9b;
  --red: #e3342f;
  --orange: #f6993f;
  --yellow: #ffed4a;
  --green: #38c172;
  --teal: #4dc0b5;
  --cyan: #6cb2eb;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #3490dc;
  --secondary: #6c757d;
  --success: #38c172;
  --info: #6cb2eb;
  --warning: #ffed4a;
  --danger: #e3342f;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Nunito", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
.filey *,
.filey *::before,
.filey *::after {
  box-sizing: border-box;
}
.filey html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.filey article, .filey aside, .filey figcaption, .filey figure, .filey footer, .filey header, .filey hgroup, .filey main, .filey nav, .filey section {
  display: block;
}
.filey body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #212529;
  text-align: left;
  background-color: #f8fafc;
}
.filey [tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}
.filey hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
.filey h1, .filey h2, .filey h3, .filey h4, .filey h5, .filey h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.filey p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.filey abbr[title],
.filey abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}
.filey address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
.filey ol,
.filey ul,
.filey dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
.filey ol ol,
.filey ul ul,
.filey ol ul,
.filey ul ol {
  margin-bottom: 0;
}
.filey dt {
  font-weight: 700;
}
.filey dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
.filey blockquote {
  margin: 0 0 1rem;
}
.filey b,
.filey strong {
  font-weight: bolder;
}
.filey small {
  font-size: 80%;
}
.filey sub,
.filey sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
.filey sub {
  bottom: -0.25em;
}
.filey sup {
  top: -0.5em;
}
.filey a {
  color: #3490dc;
  text-decoration: none;
  background-color: transparent;
}
.filey a:hover {
  color: #1d68a7;
  text-decoration: underline;
}
.filey a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
.filey a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
.filey pre,
.filey code,
.filey kbd,
.filey samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}
.filey pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
.filey figure {
  margin: 0 0 1rem;
}
.filey img {
  vertical-align: middle;
  border-style: none;
}
.filey svg {
  overflow: hidden;
  vertical-align: middle;
}
.filey table {
  border-collapse: collapse;
}
.filey caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
.filey th {
  text-align: inherit;
}
.filey label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.filey button {
  border-radius: 0;
}
.filey button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
.filey input,
.filey button,
.filey select,
.filey optgroup,
.filey textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.filey button,
.filey input {
  overflow: visible;
}
.filey button,
.filey select {
  text-transform: none;
}
.filey [role=button] {
  cursor: pointer;
}
.filey select {
  word-wrap: normal;
}
.filey button,
.filey [type=button],
.filey [type=reset],
.filey [type=submit] {
  -webkit-appearance: button;
}
.filey button:not(:disabled),
.filey [type=button]:not(:disabled),
.filey [type=reset]:not(:disabled),
.filey [type=submit]:not(:disabled) {
  cursor: pointer;
}
.filey button::-moz-focus-inner,
.filey [type=button]::-moz-focus-inner,
.filey [type=reset]::-moz-focus-inner,
.filey [type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
.filey input[type=radio],
.filey input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}
.filey textarea {
  overflow: auto;
  resize: vertical;
}
.filey fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
.filey legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
.filey progress {
  vertical-align: baseline;
}
.filey [type=number]::-webkit-inner-spin-button,
.filey [type=number]::-webkit-outer-spin-button {
  height: auto;
}
.filey [type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
.filey [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
.filey ::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
.filey output {
  display: inline-block;
}
.filey summary {
  display: list-item;
  cursor: pointer;
}
.filey template {
  display: none;
}
.filey [hidden] {
  display: none !important;
}
@media print {
  .filey *,
.filey *::before,
.filey *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  .filey a:not(.btn) {
    text-decoration: underline;
  }
  .filey abbr[title]::after {
    content: " (" attr(title) ")";
  }
  .filey pre {
    white-space: pre-wrap !important;
  }
  .filey pre,
.filey blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  .filey thead {
    display: table-header-group;
  }
  .filey tr,
.filey img {
    page-break-inside: avoid;
  }
  .filey p,
.filey h2,
.filey h3 {
    orphans: 3;
    widows: 3;
  }
  .filey h2,
.filey h3 {
    page-break-after: avoid;
  }
  @page {
    .filey {
      size: a3;
    }
  }
  .filey body {
    min-width: 992px !important;
  }
  .filey .container {
    min-width: 992px !important;
  }
  .filey .navbar {
    display: none;
  }
  .filey .badge {
    border: 1px solid #000;
  }
  .filey .table {
    border-collapse: collapse !important;
  }
  .filey .table td,
.filey .table th {
    background-color: #fff !important;
  }
  .filey .table-bordered th,
.filey .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .filey .table-dark {
    color: inherit;
  }
  .filey .table-dark th,
.filey .table-dark td,
.filey .table-dark thead th,
.filey .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .filey .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
.filey .b-calendar {
  display: inline-flex;
}
.filey .b-calendar .b-calendar-inner {
  min-width: 250px;
}
.filey .b-calendar .b-calendar-header,
.filey .b-calendar .b-calendar-nav {
  margin-bottom: 0.25rem;
}
.filey .b-calendar .b-calendar-nav .btn {
  padding: 0.25rem;
}
.filey .b-calendar output {
  padding: 0.25rem;
  font-size: 80%;
}
.filey .b-calendar output.readonly {
  background-color: #e9ecef;
  opacity: 1;
}
.filey .b-calendar .b-calendar-footer {
  margin-top: 0.5rem;
}
.filey .b-calendar .b-calendar-grid {
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.filey .b-calendar .b-calendar-grid .row {
  flex-wrap: nowrap;
}
.filey .b-calendar .b-calendar-grid-caption {
  padding: 0.25rem;
}
.filey .b-calendar .b-calendar-grid-body .col[data-date] .btn {
  width: 32px;
  height: 32px;
  font-size: 14px;
  line-height: 1;
  margin: 3px auto;
  padding: 9px 0;
}
.filey .b-calendar .btn:disabled, .filey .b-calendar .btn.disabled, .filey .b-calendar .btn[aria-disabled=true] {
  cursor: default;
  pointer-events: none;
}
.filey .card-img-left {
  border-top-left-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.filey .card-img-right {
  border-top-right-radius: calc(0.25rem - 1px);
  border-bottom-right-radius: calc(0.25rem - 1px);
}
.filey .dropdown:not(.dropleft) .dropdown-toggle.dropdown-toggle-no-caret::after {
  display: none !important;
}
.filey .dropdown.dropleft .dropdown-toggle.dropdown-toggle-no-caret::before {
  display: none !important;
}
.filey .dropdown .dropdown-menu:focus {
  outline: none;
}
.filey .b-dropdown-form {
  display: inline-block;
  padding: 0.25rem 1.5rem;
  width: 100%;
  clear: both;
  font-weight: 400;
}
.filey .b-dropdown-form:focus {
  outline: 1px dotted !important;
  outline: 5px auto -webkit-focus-ring-color !important;
}
.filey .b-dropdown-form.disabled, .filey .b-dropdown-form:disabled {
  outline: 0 !important;
  color: #6c757d;
  pointer-events: none;
}
.filey .b-dropdown-text {
  display: inline-block;
  padding: 0.25rem 1.5rem;
  margin-bottom: 0;
  width: 100%;
  clear: both;
  font-weight: lighter;
}
.filey .b-form-btn-label-control.form-control {
  display: flex;
  align-items: stretch;
  height: auto;
  padding: 0;
  background-image: none;
}
.input-group .filey .b-form-btn-label-control.form-control {
  padding: 0;
}

[dir=rtl] .filey .b-form-btn-label-control.form-control, .filey .b-form-btn-label-control.form-control[dir=rtl] {
  flex-direction: row-reverse;
}
[dir=rtl] .filey .b-form-btn-label-control.form-control > label, .filey .b-form-btn-label-control.form-control[dir=rtl] > label {
  text-align: right;
}

.filey .b-form-btn-label-control.form-control > .btn {
  line-height: 1;
  font-size: inherit;
  box-shadow: none !important;
  border: 0;
}
.filey .b-form-btn-label-control.form-control > .btn:disabled {
  pointer-events: none;
}
.filey .b-form-btn-label-control.form-control.is-valid > .btn {
  color: #38c172;
}
.filey .b-form-btn-label-control.form-control.is-invalid > .btn {
  color: #e3342f;
}
.filey .b-form-btn-label-control.form-control > .dropdown-menu {
  padding: 0.5rem;
}
.filey .b-form-btn-label-control.form-control > .form-control {
  height: auto;
  min-height: calc(calc(1.6em + 0.75rem + 2px) - 2px);
  padding-left: 0.25rem;
  margin: 0;
  border: 0;
  outline: 0;
  background: transparent;
  word-break: break-word;
  font-size: inherit;
  white-space: normal;
  cursor: pointer;
}
.filey .b-form-btn-label-control.form-control > .form-control.form-control-sm {
  min-height: calc(calc(1.5em + 0.5rem + 2px) - 2px);
}
.filey .b-form-btn-label-control.form-control > .form-control.form-control-lg {
  min-height: calc(calc(1.5em + 1rem + 2px) - 2px);
}
.input-group.input-group-sm .filey .b-form-btn-label-control.form-control > .form-control {
  min-height: calc(calc(1.5em + 0.5rem + 2px) - 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.input-group.input-group-lg .filey .b-form-btn-label-control.form-control > .form-control {
  min-height: calc(calc(1.5em + 1rem + 2px) - 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.filey .b-form-btn-label-control.form-control[aria-disabled=true], .filey .b-form-btn-label-control.form-control[aria-readonly=true] {
  background-color: #e9ecef;
  opacity: 1;
}
.filey .b-form-btn-label-control.form-control[aria-disabled=true] {
  pointer-events: none;
}
.filey .b-form-btn-label-control.form-control[aria-disabled=true] > label {
  cursor: default;
}
.filey .b-form-btn-label-control.btn-group > .dropdown-menu {
  padding: 0.5rem;
}
.was-validated .filey .form-control:invalid, .was-validated .filey .form-control:valid, .filey .form-control.is-invalid, .filey .form-control.is-valid {
  background-position: right calc(0.4em + 0.1875rem) center;
}
.filey input[type=color].form-control {
  height: calc(1.6em + 0.75rem + 2px);
  padding: 0.125rem 0.25rem;
}
.filey input[type=color].form-control.form-control-sm,
.filey .input-group-sm input[type=color].form-control {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.125rem 0.25rem;
}
.filey input[type=color].form-control.form-control-lg,
.filey .input-group-lg input[type=color].form-control {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.125rem 0.25rem;
}
.filey input[type=color].form-control:disabled {
  background-color: #adb5bd;
  opacity: 0.65;
}
.filey .input-group > .custom-range {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.filey .input-group > .custom-range + .form-control,
.filey .input-group > .custom-range + .form-control-plaintext,
.filey .input-group > .custom-range + .custom-select,
.filey .input-group > .custom-range + .custom-range,
.filey .input-group > .custom-range + .custom-file {
  margin-left: -1px;
}
.filey .input-group > .form-control + .custom-range,
.filey .input-group > .form-control-plaintext + .custom-range,
.filey .input-group > .custom-select + .custom-range,
.filey .input-group > .custom-range + .custom-range,
.filey .input-group > .custom-file + .custom-range {
  margin-left: -1px;
}
.filey .input-group > .custom-range:focus {
  z-index: 3;
}
.filey .input-group > .custom-range:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.filey .input-group > .custom-range:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.filey .input-group > .custom-range {
  height: calc(1.6em + 0.75rem + 2px);
  padding: 0 0.75rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  height: calc(1.6em + 0.75rem + 2px);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .filey .input-group > .custom-range {
    transition: none;
  }
}
.filey .input-group > .custom-range:focus {
  color: #495057;
  background-color: #fff;
  border-color: #a1cbef;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}
.filey .input-group > .custom-range:disabled, .filey .input-group > .custom-range[readonly] {
  background-color: #e9ecef;
}
.filey .input-group-lg > .custom-range {
  height: calc(1.5em + 1rem + 2px);
  padding: 0 1rem;
  border-radius: 0.3rem;
}
.filey .input-group-sm > .custom-range {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0 0.5rem;
  border-radius: 0.2rem;
}
.was-validated .filey .input-group .custom-range:valid, .filey .input-group .custom-range.is-valid {
  border-color: #38c172;
}
.was-validated .filey .input-group .custom-range:valid:focus, .filey .input-group .custom-range.is-valid:focus {
  border-color: #38c172;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}
.was-validated .filey .custom-range:valid:focus::-webkit-slider-thumb, .filey .custom-range.is-valid:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem #bfecd2;
}
.was-validated .filey .custom-range:valid:focus::-moz-range-thumb, .filey .custom-range.is-valid:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem #bfecd2;
}
.was-validated .filey .custom-range:valid:focus::-ms-thumb, .filey .custom-range.is-valid:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem #bfecd2;
}
.was-validated .filey .custom-range:valid::-webkit-slider-thumb, .filey .custom-range.is-valid::-webkit-slider-thumb {
  background-color: #38c172;
  background-image: none;
}
.was-validated .filey .custom-range:valid::-webkit-slider-thumb:active, .filey .custom-range.is-valid::-webkit-slider-thumb:active {
  background-color: #bfecd2;
  background-image: none;
}
.was-validated .filey .custom-range:valid::-webkit-slider-runnable-track, .filey .custom-range.is-valid::-webkit-slider-runnable-track {
  background-color: rgba(56, 193, 114, 0.35);
}
.was-validated .filey .custom-range:valid::-moz-range-thumb, .filey .custom-range.is-valid::-moz-range-thumb {
  background-color: #38c172;
  background-image: none;
}
.was-validated .filey .custom-range:valid::-moz-range-thumb:active, .filey .custom-range.is-valid::-moz-range-thumb:active {
  background-color: #bfecd2;
  background-image: none;
}
.was-validated .filey .custom-range:valid::-moz-range-track, .filey .custom-range.is-valid::-moz-range-track {
  background: rgba(56, 193, 114, 0.35);
}
.was-validated .filey .custom-range:valid ~ .valid-feedback,
.was-validated .filey .custom-range:valid ~ .valid-tooltip, .filey .custom-range.is-valid ~ .valid-feedback,
.filey .custom-range.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .filey .custom-range:valid::-ms-thumb, .filey .custom-range.is-valid::-ms-thumb {
  background-color: #38c172;
  background-image: none;
}
.was-validated .filey .custom-range:valid::-ms-thumb:active, .filey .custom-range.is-valid::-ms-thumb:active {
  background-color: #bfecd2;
  background-image: none;
}
.was-validated .filey .custom-range:valid::-ms-track-lower, .filey .custom-range.is-valid::-ms-track-lower {
  background: rgba(56, 193, 114, 0.35);
}
.was-validated .filey .custom-range:valid::-ms-track-upper, .filey .custom-range.is-valid::-ms-track-upper {
  background: rgba(56, 193, 114, 0.35);
}
.was-validated .filey .input-group .custom-range:invalid, .filey .input-group .custom-range.is-invalid {
  border-color: #e3342f;
}
.was-validated .filey .input-group .custom-range:invalid:focus, .filey .input-group .custom-range.is-invalid:focus {
  border-color: #e3342f;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}
.was-validated .filey .custom-range:invalid:focus::-webkit-slider-thumb, .filey .custom-range.is-invalid:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem #f8cecc;
}
.was-validated .filey .custom-range:invalid:focus::-moz-range-thumb, .filey .custom-range.is-invalid:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem #f8cecc;
}
.was-validated .filey .custom-range:invalid:focus::-ms-thumb, .filey .custom-range.is-invalid:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem #f8cecc;
}
.was-validated .filey .custom-range:invalid::-webkit-slider-thumb, .filey .custom-range.is-invalid::-webkit-slider-thumb {
  background-color: #e3342f;
  background-image: none;
}
.was-validated .filey .custom-range:invalid::-webkit-slider-thumb:active, .filey .custom-range.is-invalid::-webkit-slider-thumb:active {
  background-color: #f8cecc;
  background-image: none;
}
.was-validated .filey .custom-range:invalid::-webkit-slider-runnable-track, .filey .custom-range.is-invalid::-webkit-slider-runnable-track {
  background-color: rgba(227, 52, 47, 0.35);
}
.was-validated .filey .custom-range:invalid::-moz-range-thumb, .filey .custom-range.is-invalid::-moz-range-thumb {
  background-color: #e3342f;
  background-image: none;
}
.was-validated .filey .custom-range:invalid::-moz-range-thumb:active, .filey .custom-range.is-invalid::-moz-range-thumb:active {
  background-color: #f8cecc;
  background-image: none;
}
.was-validated .filey .custom-range:invalid::-moz-range-track, .filey .custom-range.is-invalid::-moz-range-track {
  background: rgba(227, 52, 47, 0.35);
}
.was-validated .filey .custom-range:invalid ~ .invalid-feedback,
.was-validated .filey .custom-range:invalid ~ .invalid-tooltip, .filey .custom-range.is-invalid ~ .invalid-feedback,
.filey .custom-range.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .filey .custom-range:invalid::-ms-thumb, .filey .custom-range.is-invalid::-ms-thumb {
  background-color: #e3342f;
  background-image: none;
}
.was-validated .filey .custom-range:invalid::-ms-thumb:active, .filey .custom-range.is-invalid::-ms-thumb:active {
  background-color: #f8cecc;
  background-image: none;
}
.was-validated .filey .custom-range:invalid::-ms-track-lower, .filey .custom-range.is-invalid::-ms-track-lower {
  background: rgba(227, 52, 47, 0.35);
}
.was-validated .filey .custom-range:invalid::-ms-track-upper, .filey .custom-range.is-invalid::-ms-track-upper {
  background: rgba(227, 52, 47, 0.35);
}
.filey .b-rating {
  text-align: center;
}
.filey .b-rating.d-inline-flex {
  width: auto;
}
.filey .b-rating .b-rating-star,
.filey .b-rating .b-rating-value {
  padding: 0 0.25em;
}
.filey .b-rating .b-rating-value {
  min-width: 2.5em;
}
.filey .b-rating .b-rating-star {
  display: inline-flex;
  justify-content: center;
  outline: 0;
}
.filey .b-rating .b-rating-star .b-rating-icon {
  display: inline-flex;
  transition: all 0.15s ease-in-out;
}
.filey .b-rating.disabled, .filey .b-rating:disabled {
  background-color: #e9ecef;
  color: #6c757d;
}
.filey .b-rating:not(.disabled):not(.readonly) .b-rating-star {
  cursor: pointer;
}
.filey .b-rating:not(.disabled):not(.readonly):focus:not(:hover) .b-rating-star.focused .b-rating-icon,
.filey .b-rating:not(.disabled):not(.readonly) .b-rating-star:hover .b-rating-icon {
  transform: scale(1.5);
}
.filey .b-rating[dir=rtl] .b-rating-star-half {
  transform: scale(-1, 1);
}
.filey .b-form-spinbutton {
  text-align: center;
  overflow: hidden;
  background-image: none;
  padding: 0;
}
[dir=rtl] .filey .b-form-spinbutton:not(.flex-column), .filey .b-form-spinbutton[dir=rtl]:not(.flex-column) {
  flex-direction: row-reverse;
}

.filey .b-form-spinbutton output {
  font-size: inherit;
  outline: 0;
  border: 0;
  background-color: transparent;
  width: auto;
  margin: 0;
  padding: 0 0.25rem;
}
.filey .b-form-spinbutton output > div,
.filey .b-form-spinbutton output > bdi {
  display: block;
  min-width: 2.25em;
  height: 1.6em;
}
.filey .b-form-spinbutton.flex-column {
  height: auto;
  width: auto;
}
.filey .b-form-spinbutton.flex-column output {
  margin: 0 0.25rem;
  padding: 0.25rem 0;
}
.filey .b-form-spinbutton:not(.d-inline-flex):not(.flex-column) {
  output-width: 100%;
}
.filey .b-form-spinbutton.d-inline-flex:not(.flex-column) {
  width: auto;
}
.filey .b-form-spinbutton .btn {
  line-height: 1;
  box-shadow: none !important;
}
.filey .b-form-spinbutton .btn:disabled {
  pointer-events: none;
}
.filey .b-form-spinbutton .btn:hover:not(:disabled) > div > .b-icon {
  transform: scale(1.25);
}
.filey .b-form-spinbutton.disabled, .filey .b-form-spinbutton.readonly {
  background-color: #e9ecef;
}
.filey .b-form-spinbutton.disabled {
  pointer-events: none;
}
.filey .b-form-tags .b-form-tags-list {
  margin-top: -0.25rem;
}
.filey .b-form-tags .b-form-tags-list .b-from-tags-field,
.filey .b-form-tags .b-form-tags-list .b-form-tag {
  margin-top: 0.25rem;
}
.filey .b-form-tags.focus {
  color: #495057;
  background-color: #fff;
  border-color: #a1cbef;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}
.filey .b-form-tags.focus.is-valid {
  border-color: #38c172;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}
.filey .b-form-tags.focus.is-invalid {
  border-color: #e3342f;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}
.filey .b-form-tags.disabled {
  background-color: #e9ecef;
}
.filey .b-form-tag {
  font-size: 75%;
  font-weight: normal;
  line-height: 1.6;
  margin-right: 0.25rem;
}
.filey .b-form-tag.disabled {
  opacity: 0.75;
}
.filey .b-form-tag > button.b-form-tag-remove {
  color: inherit;
  font-size: 125%;
  line-height: 1;
  float: none;
  margin-left: 0.25rem;
}
.filey .form-control-sm .b-form-tag {
  line-height: 1.5;
}
.filey .form-control-lg .b-form-tag {
  line-height: 1.5;
}
.filey .input-group > .input-group-prepend > .btn-group > .btn,
.filey .input-group > .input-group-append:not(:last-child) > .btn-group > .btn,
.filey .input-group > .input-group-append:last-child > .btn-group:not(:last-child):not(.dropdown-toggle) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.filey .input-group > .input-group-append > .btn-group > .btn,
.filey .input-group > .input-group-prepend:not(:first-child) > .btn-group > .btn,
.filey .input-group > .input-group-prepend:first-child > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.filey .media-aside {
  display: flex;
  margin-right: 1rem;
}
.filey .media-aside-right {
  margin-right: 0;
  margin-left: 1rem;
}
.filey .modal-backdrop {
  opacity: 0.5;
}
.filey .bv-no-focus-ring:focus {
  outline: none;
}
@media (max-width: 575.98px) {
  .filey .bv-d-xs-down-none {
    display: none !important;
  }
}
@media (max-width: 767.98px) {
  .filey .bv-d-sm-down-none {
    display: none !important;
  }
}
@media (max-width: 991.98px) {
  .filey .bv-d-md-down-none {
    display: none !important;
  }
}
@media (max-width: 1199.98px) {
  .filey .bv-d-lg-down-none {
    display: none !important;
  }
}
.filey .bv-d-xl-down-none {
  display: none !important;
}
.filey .b-pagination-pills .page-item .page-link {
  border-radius: 50rem !important;
  margin-left: 0.25rem;
  line-height: 1;
}
.filey .b-pagination-pills .page-item:first-child .page-link {
  margin-left: 0;
}
.filey .b-time {
  min-width: 150px;
}
.filey .b-time[aria-disabled=true] output, .filey .b-time[aria-readonly=true] output,
.filey .b-time output.disabled {
  background-color: #e9ecef;
  opacity: 1;
}
.filey .b-time[aria-disabled=true] output {
  pointer-events: none;
}
[dir=rtl] .filey .b-time > .d-flex:not(.flex-column) {
  flex-direction: row-reverse;
}

.filey .b-time .b-time-header {
  margin-bottom: 0.5rem;
}
.filey .b-time .b-time-header output {
  padding: 0.25rem;
  font-size: 80%;
}
.filey .b-time .b-time-footer {
  margin-top: 0.5rem;
}
.filey .b-time .b-time-ampm {
  margin-left: 0.5rem;
}