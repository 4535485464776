.filey {
    font-family: "Nunito", sans-serif;
    font-size: 16px;
}
/* Sweet Alert Pop-ups */
.swal2-container, 
.swal2-container h1, 
.swal2-container h2, 
.swal2-container h3, 
.swal2-icon {
	font-family: "Nunito", sans-serif;
}
.filey .clear {
    color:rgba(0,0,0,0);
}
.filey h2 {
    font-size: 24px !important;
}
.filey a {
  color: #3490dc;
}
.filey a:hover {
  color: #1d68a7;
}
.filey .page-item.active .page-link {
  background-color: #3490dc;
  border-color: #3490dc;
}
.filey .page-item.disabled .page-link {
  background-color: #fff;
  border-color: #dee2e6;
}
.filey .btn {
    font-size: 14px !important;
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: inherit;
    font-family: "Nunito", sans-serif;
}
.filey .btn-sm, .btn-group-sm > .btn {
    font-size: 14px !important;
    padding: 4px 8px;
	border-radius: 3px;
}
.filey .btn, .filey .btn-sm {
    font-size: 14px !important;
}
.filey .btn-primary {
  background-color: #3490dc;
  border-color: #3490dc;
}
.filey .btn-primary:hover {
  background-color: #227dc7;
  border-color: #2176bd;
}
.filey .pointer {
    cursor: pointer;
}
.filey .form-control:focus {
  border-color: #a1cbef;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}
.filey .col-form-label {
    line-height: 1.2 !important;
}
.filey ul {
    margin-top: 0px !important; 
    margin-bottom: 15px !important;  
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.filey .searchbox {
    margin-bottom: 15px;
    margin-top: 5px;
}
.filey table.loading td {
    border-top:0;
}
.filey .searchresults {
	width: 100%;
    min-height: 120px;
}
.filey tr.loading td {
    border-top:none;
    color: #888888;
}


/* --- USER LIST --- */

.filey .user-list-table th {
	padding: 8px !important;
    padding-top: 13px !important;
    padding-bottom: 13px !important;
    vertical-align: middle !important;
}
.filey .user-list-table td {
	padding: 8px !important;
    vertical-align: middle !important;
}
.filey .user-list .file-list td {
    font-size: 14px;
}
.filey .user-list .user-list-table {
    border: solid 1px #dee2e6;
}
.filey .user-list-header {
    background-color: #f5f5f5;
}
.filey .user-list .fa-caret-right, .filey .user-list .fa-caret-down {
    font-size: 20px;
    padding-top: 3px;
}
.filey .user-list-table td.expand {
    cursor: pointer;
}
.filey .user-list-table .loading td {
    padding: 10px !important;
}
.filey .colCaret {
    width: 30px;
}
.filey .has-open-files {
    background-color: #ececec !important;
}
.filey .invalid-feedback {
	display: block !important;            /* VForm has this hidden for some reason - probably a bug */
}
.filey .pagination {
    margin-top: 15px;
}
.filey .loading-user-list.spinner-border {
	margin: 10px;
}


/* --- FILE LIST --- */

.filey .file-list-header {
    background-color: #f5f5f5;
}
.filey .file-list-header th {
    line-height: 18px !important;
}
.filey .file-list-table {
    border: solid 1px #dee2e6;
}
.filey .file-list i.fa-cloud-download-alt, 
.filey .file-list i.fa-edit {
    padding-right: 3px;
}
.filey .file-list i.new, .filey .you-have-new-files-row i.new {
    font-size: 10px;
    vertical-align: 1px;
    color: #ffff55;
    text-shadow: 
            1px 1px 0px rgba(0, 0, 0, 0.4),
            1px -1px 0px rgba(0, 0, 0, 0.4),
            -1px 1px 0px rgba(0, 0, 0, 0.4),
            -1px -1px 0px rgba(0, 0, 0, 0.4);
}
.filey .file-list tr.no-files {
    background-color: #ffffff;
}
.filey .file-list tr.no-files td {
    border-top:none;
}
.filey tr.loading .loading1 {
    animation: blinker 1.0s linear infinite;
    animation-delay: .33s;
}
.filey tr.loading .loading2 {
    animation: blinker 1.0s linear infinite;
    animation-delay: -.33s;
}
.filey tr.loading .loading3 {
    animation: blinker 1.0s linear infinite;
    animation-delay: 0s;
}
@keyframes blinker {
    0%  { opacity: 0; }  
    33% { opacity: 1; }
    67% { opacity: 1; }
    100% { opacity: 0; }
}

.filey progress {
    width: 100%
}

.filey .you-have-new-files-row td.expand {
    width:20px;
}

.filey footer.filey-footer {
    font-size: 13px;
}


/* --- Responsive styles --- */

@media only screen and (max-width: 992px) {
    .filey .table-responsive {
        margin-bottom: 0 !important;
    }
    .filey .user-list .card-body {
        padding:0 !important;
    }
    .filey .container {
        max-width: 100% !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .filey .pagination, .filey .show-all-clients-button {
        margin-top: 15px !important;
    }
    .filey .add-file-button-large, 
    .filey .request-file-button-large, 
    .filey .add-new-user-button-large,
    .filey .settings-button-label,
    .filey .log-out-button-label {
        display:none !important;
    }
    .filey .add-file-button-small, 
    .filey .request-file-button-small, 
    .filey .add-new-user-button-small {
        display:inline-block !important;
    }
    .filey .file-list {
        font-size:14px;
    }
    .filey .file-list i {
        font-size:16px;
    }


    /*  .filey .file-list-table > tbody > tr td {
	 	display: inline-block !important;
        width: 100% !important;
	}
    .filey .file-list-table > thead th {
        position: absolute;
        top: -9999px;
        left: -9999px;
    } */

}



/* --- Fixes for SCSS compiler not adding .filey correctly --- */
.filey .modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .filey .modal.fade .modal-dialog {
    transition: none;
  }
}
.filey .modal.show .modal-dialog {
  transform: none;
}
.filey .modal.modal-static .modal-dialog {
  transform: scale(1.02);
}


/* --- Fixes for external bootstrap conflicts --- */
.filey .fade {
    opacity: inherit !important;
}
.filey h1, .filey h2, .filey h3, .filey h4, .filey h5, .filey h6 {
    margin-top: 0;
    color: #333333;
}
.filey .modal-backdrop.show {
  opacity: 0.75 !important;
}
.filey .modal-backdrop {
    opacity: 0.75 !important;
}
.filey .modal-backdrop {
	background-color: rgba(0, 0, 0, 0.75) !important;
}
.filey .form-control {
    font-size: 16px !important;
}
html {
    font-size: inherit;
}
.filey .modal-footer > .btn {
    margin-bottom: 0px;
}
.filey label {
    font-weight:400;
}

